import {
    Table, TableBody, TableCell, TableFooter, TableContainer, TableHead, TableRow,
    Typography, Grid, CardContent, Card, Box,
    Button,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useState } from 'react';

import { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';
import { convertMask } from '../../../../../components/Masks/DollarInput';
import { formatCurrency } from '../../../../../services/ApiService';
import ApiService from '../../../../../services/ApiService';
import SelectDataInvoice from '../Selects/SelectDataInvoice';
import ModalPPH23 from '../Modals/ModalPPH23';
import ModalAllPPH23 from '../Modals/ModalAllPPH23';
import { Icon, Input, showToast } from '../../../../../components/ui';
import { defaultDate, updateDateFormat } from '../../../../../utils/date';
import { convertToNominal } from '../../../../../utils/masks';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import TabTemporaryReceipt from '../Tabs/TabTemporaryReceipt';
import TabReceiptVoucher from '../Tabs/TabReceiptVoucher';

const TableInvoice = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const StyledTableFooterCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.footer}`]: {
            background: '#E4E8EC',
            color: '#083A50 !important',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '10px 16px !important',
            left: 0,
            bottom: 0,
            zIndex: 2,
            position: 'sticky',
        },
    }));

    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailSequence, setDetailSequence] = useState(0);

    const [isModalAll, setIsModalAll] = useState(false);

    const paramI = "invoice/invoice";
    const [isLoadingI, setIsLoadingI] = useState(false);
    const [dataI, setDataI] = useState([]);
    const [SelectedDataI, setSelectedDataI] = useState({});
    const [totalRowsI, setTotalRowsI] = useState(50);
    const [dataMapI, setDataMapI] = useState([]);
    const [columnDataI, setColumnDataI] = useState([]);

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');
    const [InvoiceId, setInvoiceId] = useState(0);
    const [InvoiceNo, setInvoiceNo] = useState('');
    const [Include, setInclude] = useState(0);
    const [Amount, setAmount] = useState(0);
    const [Remarks, setRemarks] = useState('');
    const [TotalUSD, setTotalUSD] = useState(0);
    const [TotalIDR, setTotalIDR] = useState(0);
    const [Tax, setTax] = useState(0);

    const [checkedData, setCheckedData] = useState([]);

    const [isInclude, setIsInclude] = useState(true);

    const [isTR, setIsTR] = useState(false);
    const [isRV, setIsRV] = useState(false);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataInvoice(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const ShowAddInvoice = () => {
        if (props.CustomerId !== 0) {
            setTitleModal('Add Invoice');
            if (props.InvoiceList.length > 0) {
                let data = props.InvoiceList.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                setDetailSequence(data.sequence);
            }
            else {
                setDetailSequence(0);
            }
            handleClick();
        }
        else {
            showToast({ type: 'error', message: 'Please Select Customer First!' });
        }
    };

    const DeleteInvoice = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;
            let tempId = selectedDetail.invoiceId;
            let tempIdTR = [];
            let tempIdRV = [];

            const deleteFunction1 = (data) => {
                return data.sequence !== tempSequence;
            }

            const deleteFunction2 = (data) => {
                return data.id !== tempId;
            }

            const deleteFunction3 = (data) => {
                return data !== tempId;
            }

            const result = props.InvoiceList.filter(deleteFunction1);

            const result2 = checkedData.filter(deleteFunction2);

            const result3 = props.invoiceIdList.filter(deleteFunction3);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            let dateDueLast = defaultDate;
            result.forEach(element => {
                if (element.officialReceiptInvoiceTRs.length > 0) {
                    element.officialReceiptInvoiceTRs.forEach(ele => {
                        tempIdTR = [...tempIdTR, ele.trDetailId];
                    });
                }

                if (element.officialReceiptInvoiceRVs.length > 0) {
                    element.officialReceiptInvoiceRVs.forEach(el => {
                        tempIdRV = [...tempIdRV, el.rvDetailId];
                    });
                }

                if (element.officialReceiptInvoiceBanks.length > 0) {
                    element.officialReceiptInvoiceBanks.forEach(elem => {
                        if (new Date(dateDueLast) < new Date(elem.dueDate)) {
                            dateDueLast = elem.dueDate;
                        }
                    });
                }
            });

            if (dateDueLast === defaultDate) {
                dateDueLast = updateDateFormat(new Date());
            }
            props.setDueDateLast(dateDueLast);


            props.setTRIdList(tempIdTR);
            props.setRVIdList(tempIdRV);

            props.setInvoiceList(result);
            setCheckedData(result2);
            props.setInvoiceIdList(result3);

            props.setCheckSelectedInvoice({});

            props.setBankData([]);

            props.calculateTotalInvoice(result);
            props.calculateTotalPPh23(result);
            props.calculateTotalCash(result);
            props.calculateTotalBank(result);
            props.calculateTotalRV(result);
            props.calculateTotalTR(result);
            props.calculateTotalDifferent(result);

            setSelectedDetail({});
            showToast({ type: 'success', message: 'Invoice Deleted Successfully!' });
        }
    }

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
        }
        else {
            setIsModal2(false);
        }
    };

    const handleClickAll = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let check = 0;
            let tempArray = props.InvoiceList;
            tempArray.forEach(element => {
                if (element.isPPh23 === 1) {
                    check = 1;
                }
            });

            if (check === 0) {
                if (isModalAll === false) {
                    setIsModalAll(true);
                }
                else {
                    setIsModalAll(false);
                }
            }
            else {
                ShowAddPPH23();
            }
        }
    }

    const SetAllPPH23 = (value) => {
        if (value === 'All') {
            let tax = 0;

            if (props.isNPWP === true) {
                tax = 2;
            }
            else {
                tax = 4;
            }

            const newArr = props.InvoiceList.slice();
            newArr.forEach(el => {
                let countAmount = 0;
                countAmount = parseFloat(parseFloat(parseFloat(parseFloat(el.paymentNetUSD)) * parseFloat(props.RateCount)) + parseFloat(parseFloat(el.paymentNetIDR))) * parseFloat(tax) / parseFloat(100);

                el.isPPh23 = 1;
                el.pPh23Amount = parseFloat(countAmount);
                el.pPh23Remarks = '';
            })

            props.setInvoiceList(newArr);

            props.calculateTotalPPh23();
            props.calculateTotalDifferent(newArr);
            props.calculateTotalInvoice(newArr);
        }
        else {
            ShowAddPPH23();
        }

        setIsModalAll(false);
    }

    const ShowAddPPH23 = () => {
        if (props.isNPWP === true) {
            setTax(2);
        }
        else {
            setTax(4);
        }
        setInvoiceId(selectedDetail.invoiceId);
        setInvoiceNo(selectedDetail.invoiceNo);
        setAmount(selectedDetail.pPh23Amount);
        setInclude(selectedDetail.isPPh23);
        setRemarks(selectedDetail.pPh23Remarks);
        setTotalIDR(parseFloat(selectedDetail.paymentNetIDR));
        setTotalUSD(parseFloat(selectedDetail.paymentNetUSD));

        if (selectedDetail.isPPh23 === 1) {
            setIsInclude(false);
        }
        else {
            setIsInclude(true);
        }

        setTitleModal2('Edit PPh 23');

        handleClick2();
    };

    const handleSave = () => {
        let include = 0;
        let amount = 0;
        let remarks = '';
        let check = 0;

        if (parseInt(Include) === 1) {
            let tempAmount = 0;

            if (formatCurrency === 'USD') {
                tempAmount = parseFloat(convertMask(Amount));
            }
            else {
                tempAmount = parseFloat(convertMaskIDR(Amount));
            }

            if (tempAmount <= 0) {
                showToast({ type: 'error', message: 'Please Input Amount!' });
            }
            else {
                include = parseInt(Include);
                amount = tempAmount;
                remarks = Remarks;
            }
        }

        if (check === 0) {
            let data = {
                "invoiceId": InvoiceId,
                "isPPh23": include,
                "pPh23Amount": amount,
                "pPh23Remarks": remarks,
            }
            const newArr = props.InvoiceList.slice();
            newArr.forEach(el => {
                if (el.invoiceId === data.invoiceId) {
                    el.isPPh23 = data.isPPh23;
                    el.pPh23Amount = parseFloat(data.pPh23Amount);
                    el.pPh23Remarks = data.pPh23Remarks;
                }
            })
            props.setInvoiceList(newArr);

            props.calculateTotalPPh23();
            props.calculateTotalDifferent(newArr);
            props.calculateTotalInvoice(newArr);

            setInclude(0);
            setAmount(0);
            setRemarks('');
            handleClick2();
        }
    }

    function handleSelectedData(val) {
        let count = 0;
        count = (parseInt(val.totalCashUSD) * parseInt(props.RateCount)) + parseInt(val.totalCashIDR);
        setSelectedDetail(val);
        props.setCheckSelectedInvoice(val);

        props.setBankData([]);
        props.setRVList([]);
        props.setTRList([]);

        let formatInIDR = convertToNominal(count);

        props.setCashPaymentUSD(val.totalCashUSD);
        props.setCashPaymentIDR(val.totalCashIDR);
        props.setTotalCashPaymentIDR(formatInIDR);

        props.setBankData(val.officialReceiptInvoiceBanks);
        props.setRVList(val.officialReceiptInvoiceRVs);
        props.setTRList(val.officialReceiptInvoiceTRs);
    }

    const getDataInvoice = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingI(true);
        let params = [];
        params = [
            ...params,
            { 'attr': 'customerTypeId', 'value': '' + props.PaymentByCode },
            { 'attr': 'customerId', 'value': '' + props.CustomerId },
            { 'attr': 'debetCredit', 'value': 'D' },
            { 'attr': 'deleteType', 'value': '0' },
            { 'attr': 'ORId', 'value': '0' },
            { 'attr': 'isPosted', 'value': 'true' }
        ]
        ApiService.OperationDynamicPostByPage(paramI, 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataI({})

                    let allData = response.data.data.invoices;

                    setDataI(allData);

                    let temp = allData
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapI(indexed)
                    setColumnDataI(response.data.data.columns.headerColumns)
                    setTotalRowsI(response.data.totalRowCount)

                    setIsLoadingI(false)
                }
                setIsLoadingI(false)
            })
            .catch(function (error) {
                setIsLoadingI(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    function handleTR(isTR) {
        if (selectedDetail?.invoiceId !== undefined) {
            setIsTR(isTR);
            setIsRV(false);
        }
        else {
            showToast({ type: 'error', message: 'Please Select Invoice First!' });
        }
    }

    function handleRV(isRV) {
        if (selectedDetail?.invoiceId !== undefined) {
            setIsRV(isRV);
            setIsTR(false);
        }
        else {
            showToast({ type: 'error', message: 'Please Select Invoice First!' });
        }
    }

    return (
        <>
            <Box component="div">
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                    }}
                >
                    Data Invoice
                </Typography>

                <TableContainer sx={{ maxHeight: 375, marginTop: '8px', background: '#FFF', borderRadius: '12px' }}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead
                            sx={{
                                '& th:first-of-type': {
                                    borderRadius: '12px 0 0'
                                },
                                '& th:last-child': {
                                    borderRadius: '0 12px 0 0'
                                }
                            }}
                        >
                            <TableRow>
                                <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Invoice No</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Shipment No</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Rate</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>PPh23</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Payment USD</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Payment IDR</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.InvoiceList.length > 0 ?
                                    props.InvoiceList.map((el) => {
                                        return (
                                            <TableRow
                                                key={el.sequence}
                                                onClick={() => handleSelectedData(el)}
                                                sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                hover={true}
                                            >
                                                <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.invoiceNo}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.shipmentNo}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.rate)}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.pPh23Amount)}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.paymentUSD)}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.paymentIDR)}</StyledTableBodyCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px',
                                                    fontstyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal'
                                                }}
                                            >
                                                Data Empty
                                            </Typography>
                                        </StyledTableBodyCell>
                                    </TableRow>
                            }
                        </TableBody>
                        {
                            props.InvoiceList.length > 0 && (
                                <TableFooter
                                    sx={{
                                        left: 0,
                                        bottom: 0,
                                        zIndex: 2,
                                        position: 'sticky',
                                        background: '#E4E8EC',
                                    }}
                                >
                                    <TableRow>
                                        <StyledTableFooterCell colSpan={3} sx={{ textAlign: 'center', }}>
                                            Total Amount
                                        </StyledTableFooterCell>

                                        <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                                            {convertToNominal(props.TotalPPh)}
                                        </StyledTableFooterCell>

                                        <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                                            {convertToNominal(props.InvoiceUSD)}
                                        </StyledTableFooterCell>

                                        <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                                            {convertToNominal(props.InvoiceIDR)}
                                        </StyledTableFooterCell>
                                    </TableRow>

                                    <TableRow>
                                        <StyledTableFooterCell colSpan={3} sx={{ textAlign: 'center', }}>
                                            Total Amount In IDR
                                        </StyledTableFooterCell>

                                        <StyledTableFooterCell />

                                        <StyledTableFooterCell colSpan={2} sx={{ textAlign: 'right', }}>
                                            {convertToNominal(props.TotalInvoiceIDR)}
                                        </StyledTableFooterCell>
                                    </TableRow>
                                </TableFooter>
                            )
                        }
                    </Table>
                </TableContainer>

                <div style={{ marginTop: '16px' }}>
                    <Grid container item direction="row">
                        <Grid item xs={6} display={'flex'} sx={{ gap: '10px' }}>
                            {ButtonComponent.AddButton(ShowAddInvoice, '', (props.Printing > 0 ? true : false))}
                            {ButtonComponent.DeleteButton(DeleteInvoice, '', (props.Printing > 0 ? true : false))}
                        </Grid>

                        <Grid item xs={6} className='text-end' display={'flex'} justifyContent={'flex-end'} sx={{ gap: '10px' }}>
                            {ButtonComponent.PPH23Button(handleClickAll, (props.Printing > 0 ? true : false))}
                            {ButtonComponent.OpenCollapseButton(isTR, handleTR, 'Temporary Receipt')}
                            {ButtonComponent.OpenCollapseButton(isRV, handleRV, 'Receipt Voucher')}
                        </Grid>
                    </Grid>
                </div>

                <Card component="div" hidden={!isTR} style={{ marginTop: '24px', borderRadius: '8px', backgroundColor: '#FFFFFF', padding: '0px 24px' }}>
                    <CardContent>
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                marginBottom: '24px'
                            }}
                        >
                            Temporary Receipt - {selectedDetail.invoiceNo}
                        </Typography>

                        <TabTemporaryReceipt
                            calculateTotalTR={props.calculateTotalTR}
                            Printing={props.Printing}
                            calculateTotalDifferent={props.calculateTotalDifferent}
                            TRList={props.TRList}
                            setTRList={props.setTRList}
                            checkSelectedInvoice={props.checkSelectedInvoice}
                            InvoiceList={props.InvoiceList}
                            setInvoiceList={props.setInvoiceList}
                            TRIdList={props.TRIdList}
                            setTRIdList={props.setTRIdList}
                        />
                    </CardContent>
                </Card>

                <Card component="div" hidden={!isRV} style={{ marginTop: '24px', borderRadius: '8px', backgroundColor: '#FFFFFF', padding: '0px 24px' }}>
                    <CardContent>
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                marginBottom: '24px'
                            }}
                        >
                            Receipt Voucher - {selectedDetail.invoiceNo}
                        </Typography>

                        <TabReceiptVoucher
                            calculateTotalRV={props.calculateTotalRV}
                            Printing={props.Printing}
                            calculateTotalDifferent={props.calculateTotalDifferent}
                            RVList={props.RVList}
                            setRVList={props.setRVList}
                            checkSelectedInvoice={props.checkSelectedInvoice}
                            InvoiceList={props.InvoiceList}
                            setInvoiceList={props.setInvoiceList}
                            RVIdList={props.RVIdList}
                            setRVIdList={props.setRVIdList}
                        />
                    </CardContent>
                </Card>

                <div style={{ marginTop: '24px' }} hidden={!isTR && !isRV}>
                    <Grid container columnSpacing={'32px'} rowSpacing={'24px'}>
                        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right !important',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    width: '100%',
                                    marginBottom: '-8px'
                                }}
                            >
                                Total Transaction
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    marginBottom: '-8px'
                                }}
                            >
                                USD
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    marginBottom: '-8px'
                                }}
                            >
                                IDR
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    marginBottom: '-8px'
                                }}
                            >
                                In IDR
                            </Typography>
                        </Grid>

                        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right !important',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    width: '100%',
                                }}
                            >
                                Invoice
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                value={convertToNominal(props.InvoiceUSD)}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                value={convertToNominal(props.InvoiceIDR)}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                value={convertToNominal(props.TotalInvoiceIDR)}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right !important',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    width: '100%',
                                }}
                            >
                                Temporary Receipt
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                value={convertToNominal(props.TRInUSD)}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                value={convertToNominal(props.TRInIDR)}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                value={convertToNominal(props.TotalTRInIDR)}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>


                        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right !important',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    width: '100%',
                                }}
                            >
                                Receipt Voucher
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                value={convertToNominal(props.RVInUSD)}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                value={convertToNominal(props.RVInIDR)}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                value={convertToNominal(props.TotalRVInIDR)}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Button
                        size='small'
                        variant="text"
                        className='d-flex align-items-center'
                        sx={{
                            marginTop: '24px',
                            padding: '3px 6px',
                            gap: '4px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '18px !important',
                            minHeight: '28px !important',
                            borderRadius: '8px',
                        }}
                        onClick={() => { setIsTR(false); setIsRV(false) }}
                    >
                        <Icon
                            svgIconProps={{
                                htmlColor: '',
                                sx: {
                                    height: '12px',
                                    width: '12px',
                                    marginTop: (!isTR && !isRV) ? '1px !important' : '0px !important',
                                }
                            }}
                            iconName={(isTR || isRV) ? 'arrow-up' : 'arrow-down'}
                        />
                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                fontSize: '12px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'center',
                                color: 'inherit',
                            }}
                        >
                            Hide {(isTR && isRV) ? 'Payment Detail' : isTR ? 'Temporary Receipt' : isRV ? 'Receipt Voucher' : ''}
                        </Typography>
                    </Button>
                </div>
            </Box>

            {
                isModal && (
                    <SelectDataInvoice
                        isModal={isModal}
                        setIsModal={setIsModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        checkedData={checkedData}
                        setCheckedData={setCheckedData}
                        calculateTotalInvoice={props.calculateTotalInvoice}
                        calculateTotalPPh23={props.calculateTotalPPh23}
                        calculateTotalCash={props.calculateTotalCash}
                        calculateTotalBank={props.calculateTotalBank}
                        calculateTotalRV={props.calculateTotalRV}
                        calculateTotalTR={props.calculateTotalTR}
                        calculateTotalDifferent={props.calculateTotalDifferent}
                        getData={getDataInvoice}
                        isLoading={isLoadingI}
                        totalRows={totalRowsI}
                        setTotalRows={setTotalRowsI}
                        SelectedData={SelectedDataI}
                        setSelectedData={setSelectedDataI}
                        data={dataI}
                        columnData={columnDataI}
                        dataMap={dataMapI}
                        PaymentByCode={props.PaymentByCode}
                        CustomerId={props.CustomerId}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        InvoiceList={props.InvoiceList}
                        setInvoiceList={props.setInvoiceList}
                        InvoiceUSD={props.InvoiceUSD}
                        setInvoiceUSD={props.setInvoiceUSD}
                        InvoiceIDR={props.InvoiceIDR}
                        setInvoiceIDR={props.setInvoiceIDR}
                        TotalInvoiceIDR={props.TotalInvoiceIDR}
                        setTotalInvoiceIDR={props.setTotalInvoiceIDR}
                        officialReceiptId={props.officialReceiptId}
                        invoiceIdList={props.invoiceIdList}
                        setInvoiceIdList={props.setInvoiceIdList}
                    />
                )
            }

            {
                isModal2 && (
                    <ModalPPH23
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        calculateTotalPPh23={props.calculateTotalPPh23}
                        calculateTotalInvoice={props.calculateTotalInvoice}
                        calculateTotalDifferent={props.calculateTotalDifferent}
                        InvoiceList={props.InvoiceList}
                        setInvoiceList={props.setInvoiceList}
                        Tax={Tax}
                        isInclude={isInclude}
                        setIsInclude={setIsInclude}
                        TotalIDR={TotalIDR}
                        TotalUSD={TotalUSD}
                        PPH23List={props.PPH23List}
                        setPPH23List={props.setPPH23List}
                        TotalPPh={props.TotalPPh}
                        setTotalPPh={props.setTotalPPh}
                        InvoiceId={InvoiceId}
                        InvoiceNo={InvoiceNo}
                        RateCount={props.RateCount}
                        Include={Include}
                        setInclude={setInclude}
                        Amount={Amount}
                        setAmount={setAmount}
                        Remarks={Remarks}
                        setRemarks={setRemarks}
                        handleSave={handleSave}
                    />
                )
            }

            {
                isModalAll && (
                    <ModalAllPPH23
                        isModal={isModalAll}
                        handleClick={handleClickAll}
                        SetPPh23={SetAllPPH23}
                    />
                )
            }
        </>
    )
}

export default TableInvoice;