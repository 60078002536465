import React, { forwardRef, useEffect, useState } from 'react'
import { Backdrop, Box, Card, CardContent, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import { useFormik } from 'formik';

import ModalSearchCustomer from './Selects/ModalSearchCustomer';
import ModalSearchMarketing from './Selects/ModalSearchMarketing';

import ButtonPerformanceRekapView from './Buttons/ButtonPerformanceRekapView';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast, TickBox } from '../../../../components/ui';

const PerformanceRekapView = forwardRef(() => {
    const parentList = [
        { id: 1001, name: 'All Job', value: 'All', checked: true, isParent: true },
        { id: 1002, name: 'All Export Job', value: 'Export', checked: true, isParent: true },
        { id: 1003, name: 'All Import Job', value: 'Import', checked: true, isParent: true },
    ]

    const monthList = [
        { id: 1, name: 'January', quarter: 1 },
        { id: 2, name: 'February', quarter: 1 },
        { id: 3, name: 'March', quarter: 1 },
        { id: 4, name: 'April', quarter: 2 },
        { id: 5, name: 'May', quarter: 2 },
        { id: 6, name: 'June', quarter: 2 },
        { id: 7, name: 'July', quarter: 3 },
        { id: 8, name: 'August', quarter: 3 },
        { id: 9, name: 'September', quarter: 3 },
        { id: 10, name: 'October', quarter: 4 },
        { id: 11, name: 'November', quarter: 4 },
        { id: 12, name: 'December', quarter: 4 },
    ]

    const quarterList = [
        { id: 1, name: 'January - March' },
        { id: 2, name: 'April - June' },
        { id: 3, name: 'July - September' },
        { id: 4, name: 'October - December' },
    ]

    const date = new Date()

    const [isLoading, setIsLoading] = useState(false)
    const [jobTypeList, setJobTypeList] = useState([])
    const [shipmentList, setShipmentList] = useState([])
    const [contactTypeList, setContactTypeList] = useState([])

    const [activeModal, setActiveModal] = useState('')
    const [selectedContact, setSelectedContact] = useState({})
    const [selectedMarketing, setSelectedMarketing] = useState({})

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Performance Recap Report', link: '/Majura/PerformanceRekapReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Performance - Rekap - ' + JSON.parse(localStorage.getItem('branchName'));

        getAdditionalData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const errorToast = (location) => {
        showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
    }

    const getAdditionalData = async () => {
        setIsLoading(true)
        try {
            const [resJT, resCT, resSS] = await Promise.all([
                ApiService.PostByPage('regJobType/regJobType', 1, 99999, []),
                ApiService.PostByPage('contactType/contactType', 1, 99999, []),
                ApiService.PostByPage('shipmentStatus/shipmentStatus', 1, 99999, []),
            ])

            if (resJT?.data?.code === 200) {
                const tempData = resJT.data.data.jobType
                const mapData = tempData.map((dt) => {
                    return {
                        id: dt.jobTypeId,
                        value: dt.jobTypeId,
                        name: dt.name,
                        serviceType: dt.serviceType,
                        checked: true,
                        isParent: false
                    }
                })

                setJobTypeList([...parentList, ...mapData])
            }

            if (resCT?.data?.code === 200) {
                let data = resCT.data.data.contactType
                const includesData = ['AGENT', 'SHIPPER', 'CONSIGNEE', 'SSLINE', 'IATA']
                data = data.filter((el) => includesData.includes(el?.name?.toUpperCase()))
                data = [...data, { id: 99, name: 'Notify Party' }];
                formik.setFieldValue('contactType', data[0]?.id)
                setContactTypeList(data)
            }

            if (resSS?.data?.code === 200) {
                setShipmentList(resSS.data.data.shipmentStatus)
            }

        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }

    const handleChangeJobType = (value, e) => {
        let arrData = [...jobTypeList]
        if (typeof value === 'number') {
            // * Handle change of children checkbox
            const index = arrData.findIndex((jt) => jt.value === value)
            if (index !== -1) {
                arrData[index].checked = e.target.checked
                setJobTypeList(arrData)
            }
        } else {
            // * Handle change of parent checkbox
            const mapData = arrData.map((dt) => {
                let isChecked = e.target.checked
                if (value !== 'All' && value !== dt.serviceType) {
                    isChecked = dt.checked
                }

                return { ...dt, checked: isChecked }
            })
            setJobTypeList(mapData)
        }
    }

    const checkParent = (id) => {
        // * Get children based on Id
        const children = jobTypeList.filter((jt) => !jt.isParent && (id !== 'All' ? jt.serviceType === id : true))

        let isChecked = false
        if (children.every((child) => child.checked)) {
            isChecked = true
        }

        return isChecked
    }

    const checkIndeterminate = (id) => {
        // * Get children based on Id
        const children = jobTypeList.filter((jt) => !jt.isParent && (id !== 'All' ? jt.serviceType === id : true))

        let isIndeterminate = false
        const checkedLength = children.filter((child) => child.checked).length
        if (checkedLength !== 0 && checkedLength !== children.length) {
            isIndeterminate = true
        }

        return isIndeterminate
    }

    const searchModal = (target) => {
        setActiveModal(target)
    }

    const getQuarter = () => {
        const monthIndex = date.getMonth()
        const quarter = monthList[monthIndex]?.quarter

        return quarter
    }

    const formik = useFormik({
        initialValues: {
            shipmentOrder: '',
            contact: 0,
            contactType: 0,
            shipment: 'all',
            marketing: 0,
            range: 'monthly',
            year: date.getFullYear(),
            monthly: (date.getMonth() + 1),
            quarterly: getQuarter(),
            startDate: date.toLocaleDateString("sv-se").split('T')[0],
            endDate: date.toLocaleDateString("sv-se").split('T')[0],
        },
        validationSchema: undefined,
    })

    function checkInput() {
        let payload = formik.values;
        setIsLoading(true);

        if (payload.shipment === '') {
            setIsLoading(false);
            showToast({ type: 'error', message: 'Please Choose One Shipment' });
            return 1;
        }
        else {
            return 0;
        }
    }

    const handleExport = (format) => {
        if (checkInput() === 0) {
            let payload = formik.values;
            let variable = '';
            let params = [];

            let shipmentGroup = 0;
            let jobTypeId = 0;
            let customerType = '';
            let customerId = 0;
            let marketCode = 0;
            let shipmentStatus = 'all';
            let startDate = '';
            let endDate = '';

            if (checkUniform(jobTypeList) === false) {
                shipmentGroup = 3;
            }

            if (shipmentGroup === 3) {
                let check = false;
                let tempArray = jobTypeList.filter(data => data.id >= 9 && data.id <= 10);

                tempArray.forEach(element => {
                    if (element.checked === true) {
                        check = true;
                    }
                });

                if (check === false) {
                    let tempOdd = jobTypeList.filter(data => data.id % 2 === 1 && data.id < 9);
                    let tempEven = jobTypeList.filter(data => data.id % 2 === 0 && data.id < 9);

                    if (checkUniform(tempOdd) === true && checkUniform(tempEven) === true) {
                        shipmentGroup = 3;
                    }
                    else if (checkUniform(tempOdd) === true && checkUniform(tempEven) === false) {
                        shipmentGroup = 1;

                        for (let i = 0; i < tempEven.length; i++) {
                            if (tempEven[i].checked === true) {
                                shipmentGroup = 3;
                                break;
                            }
                        }
                    }
                    else if (checkUniform(tempOdd) === false && checkUniform(tempEven) === true) {
                        shipmentGroup = 2;

                        for (let i = 0; i < tempOdd.length; i++) {
                            if (tempOdd[i].checked === true) {
                                shipmentGroup = 3;
                                break;
                            }
                        }
                    }
                }

                if (shipmentGroup === 3) {
                    let array = jobTypeList.filter(data => data.id <= 10);
                    array = array.filter(data => data.checked === true);
                    for (let i = 0; i < array.length; i++) {
                        if (array[i].checked === true) {
                            if (i + 1 === array.length) {
                                jobTypeId = jobTypeId + array[i].id;
                            }
                            else {
                                jobTypeId = jobTypeId + array[i].id + ',';
                            }
                        }
                    }
                }
            }

            if (payload.contact !== 0) {
                contactTypeList.forEach(element => {
                    if (element.id === payload.contactType) {
                        customerType = element.name;
                    }
                });

                if (customerType === 'N Party') {
                    customerType = 'nparty';
                }

                customerId = selectedContact.contactId;
            }

            if (payload.shipment !== 'all') {
                shipmentStatus = payload.shipment.charAt(0);

                if (payload.shipment === 'Freehand' && payload.marketing === 1) {
                    marketCode = selectedMarketing.id;
                }
            }

            if (payload.range === 'monthly') {
                startDate = new Date(payload.year, payload.monthly, 1).toLocaleDateString("sv-se").split('T')[0];
                endDate = new Date(payload.year, payload.monthly + 1, 0).toLocaleDateString("sv-se").split('T')[0];
            }
            else if (payload.range === 'quarterly') {
                let startMonth = (payload.quarterly - 1) * 3 + 1;
                let endMonth = startMonth + 2;

                startDate = new Date(payload.year, startMonth - 1, 1).toLocaleDateString("sv-se").split('T')[0];
                endDate = new Date(payload.year, endMonth, 0).toLocaleDateString("sv-se").split('T')[0];
            }
            else if (payload.range === 'annually') {
                startDate = new Date(payload.year, 0, 1).toLocaleDateString("sv-se").split('T')[0];
                endDate = new Date(payload.year + 1, 0, 0).toLocaleDateString("sv-se").split('T')[0];
            }
            else if (payload.range === 'custom') {
                startDate = new Date(payload.startDate).toLocaleDateString("sv-se").split('T')[0];
                endDate = new Date(payload.endDate).toLocaleDateString("sv-se").split('T')[0];
            }

            variable = 'PrintPerformanceRekap?reportType=' + format;
            params = [
                { "attr": "ShipmentGroup", "value": "" + shipmentGroup }, { "attr": "JobTypeId", "value": "" + jobTypeId }, { "attr": "JobOwnerId", "value": "0" },
                { "attr": "Cost", "value": "2" }, { "attr": "CustomerOption", "value": "" + payload.contact }, { "attr": "CustomerType", "value": "" + customerType },
                { "attr": "CustomerId", "value": "" + customerId }, { "attr": "GroupAgentOption", "value": "0" }, { "attr": "GroupAgentCode", "value": "0" },
                { "attr": "PODPOLOption", "value": "0" }, { "attr": "PODPOLType", "value": "" }, { "attr": "PODPOLCode", "value": "0" },
                { "attr": "ShipmentStatusOption", "value": "" + shipmentStatus }, { "attr": "MarketType", "value": "" + payload.marketing }, { "attr": "MarketCode", "value": "" + marketCode },
                { "attr": "LoadStatus", "value": "all" }, { "attr": "StartDate", "value": "" + startDate }, { "attr": "EndDate", "value": "" + endDate }
            ];

            ApiService.ReportPrint("Reports/Operation/Print/PerformanceRekapReport/", variable, params)
                .then((response) => {
                    if (response.status === 200) {
                        let rptFormat = "application/pdf";
                        if (format === "xls") {
                            rptFormat = "application/vnd.ms-excel"
                        }
                        const file = new Blob([response.data], { type: rptFormat });
                        const fileURL = URL.createObjectURL(file);

                        setIsLoading(false);
                        showToast({ type: 'success', message: 'Print Performance Rekap Report Success' });
                        window.open(fileURL);
                    }
                    else {
                        setIsLoading(false);
                        showToast({ type: 'error', message: 'Can\'t Print Performance Rekap Report, ' + response.data.message });
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    showToast({ type: 'error', message: 'Can\'t Print Performance Rekap Report, ' + error });
                });
        }
    }

    function checkUniform(list) {
        if (!list || list.length === 0) {
            console.error('List is empty or undefined.');
            return false;
        }

        for (let i = 0; i < list.length; i++) {
            if (list[i].checked !== true) {
                return false;
            }
        }

        return true;
    }

    const ButtonComponents = () => {
        return (
            <ButtonPerformanceRekapView handleExport={handleExport} />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={'Performance Recap Report'}
                        isJobType={false}
                        BreadcrumbsItems={BreadcrumbsItems}
                        ButtonComponents={ButtonComponents}
                    />

                    <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                        <div className="mt-3">
                            <Grid container spacing={'32px'}>
                                <Grid item xs={6.5}>
                                    <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    lineHeight: '20px',
                                                    marginBottom: '12px'
                                                }}
                                            >
                                                Shipment Order
                                            </Typography>

                                            <FormGroup>
                                                <Grid container spacing={'8px'}>
                                                    {
                                                        jobTypeList?.map((jt) => (
                                                            <Grid item xs={4} key={jt?.id}>
                                                                <TickBox
                                                                    label={jt.name}
                                                                    checked={jt?.isParent ? checkParent(jt.value) : jt.checked}
                                                                    onChange={(e) => handleChangeJobType(jt.value, e)}
                                                                    indeterminate={jt?.isParent ? checkIndeterminate(jt.value) : undefined}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </FormGroup>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={5.5}>
                                    <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    lineHeight: '20px',
                                                    marginBottom: '12px'
                                                }}
                                            >
                                                Shipment & Marketing
                                            </Typography>

                                            <RadioGroup
                                                row
                                                sx={{
                                                    marginTop: '4px',
                                                    gap: '20px'
                                                }}
                                                value={formik.values.shipment}
                                                onChange={(e) => {
                                                    formik.setFieldValue('shipment', e.target.value)
                                                    formik.setFieldValue('marketing', 0)
                                                    setSelectedMarketing({})
                                                }}
                                            >
                                                <FormControlLabel
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                    }}
                                                    control={<Radio size='medium' />}
                                                    key={0}
                                                    value={'all'}
                                                    label="All"
                                                />

                                                {
                                                    shipmentList.map((shipment) => (
                                                        <FormControlLabel
                                                            sx={{
                                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '16px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '20px',
                                                            }}
                                                            control={<Radio size='medium' />}
                                                            key={shipment?.id}
                                                            value={shipment?.name}
                                                            label={shipment.name}
                                                        />
                                                    ))
                                                }
                                            </RadioGroup>


                                            <Box
                                                sx={{
                                                    marginTop: '16px',
                                                    display: 'flex',
                                                    padding: '16px 16px 32px',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: '8px',
                                                    borderRadius: '8px',
                                                    border: '1px solid #CACED8',
                                                }}
                                            >
                                                <FormControl disabled={formik.values.shipment === 0 || formik.values.shipment !== 'Freehand'}>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '20px',
                                                        }}
                                                    >
                                                        Marketing Freehand
                                                    </Typography>

                                                    <RadioGroup
                                                        row
                                                        sx={{
                                                            marginTop: '4px',
                                                            gap: '20px'
                                                        }}
                                                        value={formik.values.marketing}
                                                        onChange={(e) => {
                                                            formik.setFieldValue('marketing', Number(e.target.value))
                                                            setSelectedMarketing({})
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            sx={{
                                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '16px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '20px',
                                                            }}
                                                            control={<Radio size='medium' />}
                                                            key={0}
                                                            value={0}
                                                            label="All"
                                                        />
                                                        <FormControlLabel
                                                            sx={{
                                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '16px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '20px',
                                                            }}
                                                            control={<Radio size='medium' />}
                                                            key={1}
                                                            value={1}
                                                            label="Custom"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>

                                                <Input
                                                    variant="standard"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    placeholder='Search Marketing'
                                                    value={selectedMarketing?.name || ''}
                                                    type='text'
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '12px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => searchModal('Marketing')}
                                                                    disabled={formik.values.marketing === 0}
                                                                >
                                                                    <Icon
                                                                        iconName={'search'}
                                                                        svgIconProps={{
                                                                            fontSize: 'small',
                                                                            htmlColor: '#0087C2',
                                                                            sx: {
                                                                                height: '12px',
                                                                                width: '12px'
                                                                            }
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    disabled={formik.values.marketing === 0}
                                                />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={6.5}>
                                    <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    lineHeight: '20px',
                                                    marginBottom: '12px'
                                                }}
                                            >
                                                Customer
                                            </Typography>

                                            <RadioGroup
                                                row
                                                sx={{
                                                    marginTop: '4px',
                                                    gap: '20px'
                                                }}
                                                value={formik.values.contact}
                                                onChange={(e) => {
                                                    formik.setFieldValue('contact', Number(e.target.value))
                                                    formik.setFieldValue('contactType', Number(contactTypeList[0]?.id || 0))
                                                    setSelectedContact({})
                                                }}
                                            >
                                                <FormControlLabel
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                    }}
                                                    control={<Radio size='medium' />}
                                                    key={0}
                                                    value={0}
                                                    label="All"
                                                />

                                                <FormControlLabel
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                    }}
                                                    control={<Radio size='medium' />}
                                                    key={1}
                                                    value={1}
                                                    label="Custom"
                                                />
                                            </RadioGroup>

                                            <Box
                                                sx={{
                                                    marginTop: '16px',
                                                    display: 'flex',
                                                    padding: '16px 16px 32px',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: '8px',
                                                    borderRadius: '8px',
                                                    border: '1px solid #CACED8',
                                                }}
                                            >
                                                <FormControl disabled={formik.values.contact === 0}>
                                                    <RadioGroup
                                                        row
                                                        sx={{
                                                            marginTop: '4px',
                                                            gap: '20px'
                                                        }}
                                                        value={formik.values.contactType || ''}
                                                        onChange={(e) => {
                                                            formik.setFieldValue('contactType', Number(e.target.value))
                                                            setSelectedContact({})
                                                        }}
                                                    >
                                                        <Grid container spacing={'16px'}>
                                                            {
                                                                contactTypeList.map((contact) => (
                                                                    <Grid key={contact?.id} item xs={4}>
                                                                        <FormControlLabel
                                                                            sx={{
                                                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                                                fontFamily: 'Nunito',
                                                                                fontSize: '16px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: '20px',
                                                                            }}
                                                                            control={<Radio size='medium' />}
                                                                            key={contact?.id}
                                                                            value={contact?.id}
                                                                            label={contact.name}
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            }

                                                            <Grid item xs={12}>
                                                                <Input
                                                                    variant="standard"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    fullWidth
                                                                    placeholder={'Search Customer'}
                                                                    value={selectedContact?.contactName || selectedContact?.name || ''}
                                                                    type='text'
                                                                    formControlProps={{
                                                                        sx: {
                                                                            width: '100%',
                                                                            marginTop: '12px !important'
                                                                        }
                                                                    }}
                                                                    inputBaseProps={{
                                                                        readOnly: true,
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => searchModal('Customer')}
                                                                                    disabled={formik.values.contact === 0}
                                                                                >
                                                                                    <Icon
                                                                                        iconName={'search'}
                                                                                        svgIconProps={{
                                                                                            fontSize: 'small',
                                                                                            htmlColor: '#0087C2',
                                                                                            sx: {
                                                                                                height: '12px',
                                                                                                width: '12px'
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    disabled={formik.values.contact === 0}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={5.5}>
                                    <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    lineHeight: '20px',
                                                    marginBottom: '12px'
                                                }}
                                            >
                                                Range ETD / ETA
                                            </Typography>

                                            <RadioGroup
                                                row
                                                sx={{
                                                    marginTop: '4px',
                                                    gap: '20px'
                                                }}
                                                value={formik.values.range}
                                                onChange={(e) => {
                                                    formik.setFieldValue('range', e.target.value)
                                                    formik.setFieldValue('monthly', date.getMonth() + 1)
                                                    formik.setFieldValue('quarterly', getQuarter())
                                                    formik.setFieldValue('year', date.getFullYear())
                                                    formik.setFieldValue('startDate', date.toLocaleDateString("sv-se").split('T')[0])
                                                    formik.setFieldValue('endDate', date.toLocaleDateString("sv-se").split('T')[0])
                                                }}
                                            >
                                                <FormControlLabel
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                    }}
                                                    control={<Radio size='medium' />}
                                                    key={0}
                                                    value={'monthly'}
                                                    label="Monthly"
                                                />

                                                <FormControlLabel
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                    }}
                                                    control={<Radio size='medium' />}
                                                    key={1}
                                                    value={'quarterly'}
                                                    label="Quarterly"
                                                />

                                                <FormControlLabel
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                    }}
                                                    control={<Radio size='medium' />}
                                                    key={2}
                                                    value={'annually'}
                                                    label="Annually"
                                                />

                                                <FormControlLabel
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                    }}
                                                    control={<Radio size='medium' />}
                                                    key={3}
                                                    value={'custom'}
                                                    label="Custom"
                                                />
                                            </RadioGroup>

                                            <div style={{ marginTop: '16px' }}>
                                                <Grid container spacing={'16px'}>
                                                    {
                                                        formik.values.range !== 'custom' && (
                                                            <>
                                                                {
                                                                    formik.values.range !== 'annually' && (
                                                                        <Grid item xs={8}>
                                                                            <Selection
                                                                                id='0'
                                                                                fullWidth={true}
                                                                                value={formik.values[formik.values.range]}
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(formik.values.range, e.target.value)
                                                                                    formik.setFieldValue('year', date.getFullYear())
                                                                                }}
                                                                            >
                                                                                {
                                                                                    formik.values.range === 'monthly' ?
                                                                                        monthList.map((month) => (
                                                                                            <MenuItem sx={{ fontSize: '12px' }} key={month?.id} value={month?.id}>{month.name}</MenuItem>
                                                                                        ))
                                                                                        :
                                                                                        quarterList.map((quarter) => (
                                                                                            <MenuItem sx={{ fontSize: '12px' }} key={quarter?.id} value={quarter?.id}>{quarter.name}</MenuItem>
                                                                                        ))
                                                                                }
                                                                            </Selection>
                                                                        </Grid>
                                                                    )}

                                                                <Grid item xs={formik.values.range !== 'annually' ? 4 : 12}>
                                                                    <Input
                                                                        textAlign='right'
                                                                        value={formik.values.year}
                                                                        onChange={formik.handleChange('year')}
                                                                        formControlProps={{
                                                                            sx: {
                                                                                width: '100%',
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        formik.values.range === 'custom' && (
                                                            <Grid item xs={12}>
                                                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                                                    <Input
                                                                        value={formik.values.startDate}
                                                                        onChange={(e) => formik.setFieldValue('startDate', e.target.value)}
                                                                        type={'date'}
                                                                        formControlProps={{
                                                                            sx: {
                                                                                width: '100%',
                                                                            }
                                                                        }}
                                                                    />

                                                                    <Typography>To</Typography>

                                                                    <Input
                                                                        value={formik.values.endDate}
                                                                        onChange={(e) => formik.setFieldValue('endDate', e.target.value)}
                                                                        type={'date'}
                                                                        formControlProps={{
                                                                            sx: {
                                                                                width: '100%',
                                                                            }
                                                                        }}
                                                                    />
                                                                </Stack>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Box>

            {activeModal === 'Customer' && (
                <ModalSearchCustomer
                    isModal={activeModal === 'Customer'}
                    activeModal={activeModal}
                    toggleModal={searchModal}
                    dataSelected={selectedContact}
                    onSelect={setSelectedContact}
                    errorToast={errorToast}
                    formValue={formik.values}
                    listData={contactTypeList}
                />
            )}

            {activeModal === 'Marketing' && (
                <ModalSearchMarketing
                    isModal={activeModal === 'Marketing'}
                    activeModal={activeModal}
                    toggleModal={searchModal}
                    dataSelected={selectedMarketing}
                    onSelect={setSelectedMarketing}
                    errorToast={errorToast}
                    formValue={formik.values}
                />
            )}

            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <LoadingSpinner color="inherit" />
                    </Backdrop>
                )
            }
        </>
    )
})

export default PerformanceRekapView