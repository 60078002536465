import { Box, MenuItem, Grid, Card, CardContent } from '@mui/material';
import React, { useImperativeHandle } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, userId, userCode, username } from '../../../../services/ApiService';

import ButtonCreateEditReceiptVoucher from './Buttons/ButtonCreateEditReceiptVoucher';
import ReceiptVoucherHeader from './Components/ReceiptVoucherHeader';
import ReceiptVoucherDetail from './Components/ReceiptVoucherDetail';
import ReceiptVoucherFor from './Components/ReceiptVoucherFor';
import ReceiptVoucherTotalReceipt from './Components/ReceiptVoucherTotalReceipt';
import { forwardRef } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import CustomToastContainer, { Selection, showToast } from '../../../../components/ui';
import { defaultDate, getBackDatePeriod, nullDate, setDateFormat, updateDateFormat } from '../../../../utils/date';
import { convertToNominal } from '../../../../utils/masks';

const CreateEditReceiptVoucher = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataRV"));

    const { receiptVoucherId } = useParams();
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramReceiptVoucher = 'receiptVoucher/receiptVoucher';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramGL = 'generalLedger/generalLedger';
    const paramSD = 'selectionData/selectionData';

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerData, setJobOwnerData] = useState([]);

    const history = useNavigate();
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    const [RowStatus, setRowStatus] = useState('ACT');
    const [TicketId, setTicketId] = useState(0);
    const [RVNo, setRVNo] = useState(0);
    const [RVNo2, setRVNo2] = useState('');
    const [Reference, setReference] = useState('');
    const [ReceiptBy, setReceiptBy] = useState('G');
    const [CustomerTo, setCustomerTo] = useState(0);
    const [ContactId, setContactId] = useState(0);
    const [ContactCode, setContactCode] = useState('');
    const [ContactName, setContactName] = useState('');
    const [CityId, setCityId] = useState(0);
    const [CityInt, setCityInt] = useState('');
    const [CityName, setCityName] = useState('');
    const [Address, setAddress] = useState('');
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [ExRateDate, setExRateDate] = useState('');
    const [Verify, setVerify] = useState('No');
    const [VerifyAcc, setVerifyAcc] = useState('');
    const [VerifyAccOn, setVerifyAccOn] = useState('');
    const [Printing, setPrinting] = useState(0);
    const [PrintedOn, setPrintedOn] = useState('');
    const [Difference, setDifference] = useState(0);
    const [Period, setPeriod] = useState(0);
    const [YearPeriod, setYearPeriod] = useState(0);
    const [SaveOR, setSaveOR] = useState(false);
    const [PaidPV, setPaidPV] = useState(false);
    const [PaidOn, setPaidOn] = useState('');
    const [JobOwnerId, setJobOwnerId] = useState(0);
    const [ReceiptNo2, setReceiptNo2] = useState('');
    const [ExRateId, setExRateId] = useState(0);
    const [TandaMigrasi, setTandaMigrasi] = useState(0);
    const [DeletedRemarks, setDeletedRemarks] = useState('');
    const [WFComplete, setWFComplete] = useState(0);
    const [DeletedOn, setDeletedOn] = useState('');
    const [Deleted, setDeleted] = useState(false);
    const [isApprovedAcc, setIsApprovedAcc] = useState(false);

    const [DataReceiptVoucher, setDataReceiptVoucher] = useState([]);
    const [DataReceiptVoucherId, setDataReceiptVoucherId] = useState([]);
    const [selectedReceiptVoucher, setSelectedReceiptVoucher] = useState({});
    const [BankData, setBankData] = useState([]);
    const [CashPaymentUSD, setCashPaymentUSD] = useState(0);
    const [CashPaymentIDR, setCashPaymentIDR] = useState(0);
    const [TotalBankUSD, setTotalBankUSD] = useState(0);
    const [TotalBankIDR, setTotalBankIDR] = useState(0);
    const [TotalCashUSD, setTotalCashUSD] = useState(0);
    const [TotalCashIDR, setTotalCashIDR] = useState(0);

    const [TotalDebitUSD, setTotalDebitUSD] = useState(0);
    const [TotalDebitIDR, setTotalDebitIDR] = useState(0);
    const [TotalDebitInIDR, setTotalDebitInIDR] = useState(0);
    const [TotalCreditUSD, setTotalCreditUSD] = useState(0);
    const [TotalCreditIDR, setTotalCreditIDR] = useState(0);
    const [TotalCreditInIDR, setTotalCreditInIDR] = useState(0);
    const [TotalDifference, setTotalDifference] = useState(0);
    const [TotalDifferenceReal, setTotalDifferenceReal] = useState(0);

    const [isDisable, setIsDisable] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isNot, setIsNot] = useState(true);
    const [isYes, setIsYes] = useState(false);
    const [LabelDifference, setLabelDifference] = useState('Exchange Rate Difference (Credit)');
    const [isJobOwner, setIsJobOwner] = useState(true);

    const [tabValue, setTabValue] = useState('Cash');

    const [SelectionData, setSelectionData] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isDueDate, setIsDueDate] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [backDate, setBackDate] = useState('');
    const [documentStatus, setDocumentStatus] = useState('');

    const [isUnapproved, setIsUnapproved] = useState(false);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Receipt Voucher', link: '/Majura/ReceiptVoucher', style: '' },
        { label: 'Create Receipt Voucher', link: '/Majura/ReceiptVoucher/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Receipt Voucher', link: '/Majura/ReceiptVoucher', style: '' },
        { label: 'Detail Receipt Voucher', link: '/Majura/ReceiptVoucher/' + receiptVoucherId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Receipt Voucher - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    const saveLocalData = () => {
        let id = receiptVoucherId ? receiptVoucherId : 0;
        let printDate = updateDateFormat(PrintedOn, true);
        let verifyDate = updateDateFormat(VerifyAccOn, true);
        let deleteDate = updateDateFormat(DeletedOn, true);
        let paidDate = updateDateFormat(PaidOn, true);
        let receiptByName = '';

        SelectionData.forEach(element => {
            if (element.code === ReceiptBy) {
                receiptByName = element.name;
            }
        });

        var data = {
            "receiptVoucher": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "id": id,
                "ticketId": TicketId,
                "rvNo": RVNo,
                "rvNo2": RVNo2,
                "reference": Reference,
                "receiptByCode": ReceiptBy,
                "receiptByName": receiptByName,
                "customerToId": CustomerTo,
                "contactId": ContactId,
                "contactCode": ContactCode,
                "contactName": ContactName,
                "cityId": CityId,
                "cityCode": CityInt,
                "cityName": CityName,
                "address": Address,
                "rate": RateReal,
                "exRateDate": ExRateDate,
                "approvedAccBy": VerifyAcc,
                "approvedAccOn": verifyDate,
                "printing": Printing,
                "printedOn": printDate,
                "totalBankUSD": TotalBankUSD,
                "totalBankIDR": TotalBankIDR,
                "totalCashUSD": TotalCashUSD,
                "totalCashIDR": TotalCashIDR,
                "difference": Difference,
                "period": Period,
                "yearPeriod": YearPeriod,
                "saveOR": SaveOR,
                "paidPV": PaidPV,
                "paidOn": paidDate,
                "jobOwnerId": JobOwnerId,
                "receiptNo2": ReceiptNo2,
                "exRateId": ExRateId,
                "tandaMigrasi": TandaMigrasi,
                "deletedRemarks": DeletedRemarks,
                "wfComplete": WFComplete,
                "deletedOn": deleteDate,
                "deleted": Deleted,
                "isApprovedAcc": isApprovedAcc,

                "idLama": 0,
            },
            "receiptVoucherDetails": DataReceiptVoucher,
        };

        localStorage.setItem(username + "-DataRV", JSON.stringify(data));
    }

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const getLocalData = () => {
        let arrayData = [];
        let rate = 0;
        let receiptVoucherData = dataLocal.receiptVoucher;
        let receiptVoucherDetailData = dataLocal.receiptVoucherDetails;

        rate = receiptVoucherData.rate;

        setRowStatus(receiptVoucherData.rowStatus);
        setTicketId(receiptVoucherData.ticketId);
        setRVNo(receiptVoucherData.rvNo);
        setRVNo2(receiptVoucherData.rvNo2);
        setReference(receiptVoucherData.reference);
        setReceiptBy(receiptVoucherData.receiptByCode);
        setCustomerTo(receiptVoucherData.customerToId);
        setContactId(receiptVoucherData.contactId);
        setContactCode(receiptVoucherData.contactCode);
        setContactName(receiptVoucherData.contactName);
        setCityId(receiptVoucherData.cityId);
        setCityInt(receiptVoucherData.cityCode);
        setCityName(receiptVoucherData.cityName);
        setAddress(receiptVoucherData.address);
        handleDocumentStatus(receiptVoucherData.rowStatus, receiptVoucherData.isApprovedAcc);
        setRate(convertToNominal(receiptVoucherData.rate));
        setRateReal(receiptVoucherData.rate);
        setVerifyAcc(receiptVoucherData.approvedAccBy);
        setVerify(receiptVoucherData.isApprovedAcc === true ? 'Yes' : 'No');
        setPrinting(receiptVoucherData.printing);
        setTotalBankUSD(receiptVoucherData.totalBankUSD);
        setTotalBankIDR(receiptVoucherData.totalBankIDR);
        setTotalCashUSD(receiptVoucherData.totalCashUSD);
        setTotalCashIDR(receiptVoucherData.totalCashUSD);
        setDifference(receiptVoucherData.difference);
        setPeriod(receiptVoucherData.period);
        setYearPeriod(receiptVoucherData.yearPeriod);
        setSaveOR(receiptVoucherData.saveOR);
        setPaidPV(receiptVoucherData.paidPV);
        setJobOwnerId(receiptVoucherData.jobOwnerId);
        setReceiptNo2(receiptVoucherData.receiptNo2);
        setExRateId(receiptVoucherData.exRateId);
        setTandaMigrasi(receiptVoucherData.tandaMigrasi);
        setDeletedRemarks(receiptVoucherData.deletedRemarks);
        setWFComplete(receiptVoucherData.wfComplete);
        setDeleted(receiptVoucherData.deleted);
        setIsApprovedAcc(receiptVoucherData.isApprovedAcc);

        setDateFormat(receiptVoucherData.exRateDate, setExRateDate);
        setDateFormat(receiptVoucherData.approvedAccOn, setVerifyAccOn);
        setDateFormat(receiptVoucherData.printedOn, setPrintedOn);
        setDateFormat(receiptVoucherData.paidOn, setPaidOn);
        setDateFormat(receiptVoucherData.deletedOn, setDeletedOn);

        setDataReceiptVoucher(receiptVoucherDetailData);
        arrayData = receiptVoucherDetailData;
        let tempArrayId = [];
        arrayData.forEach(element => {
            if (tempArrayId.length > 0) {
                let checked = 0;
                tempArrayId.forEach(el => {
                    if (element.refId === el) {
                        checked = 1;
                    }
                });

                if (checked === 0) {
                    tempArrayId = [...tempArrayId, element.refId];
                }
            }
            else {
                tempArrayId = [...tempArrayId, element.refId];
            }
        });
        setDataReceiptVoucherId(tempArrayId);
        calculateTotalDebitCredit(arrayData, rate);

        if (receiptVoucherId) {
            setIsEdit(true);
            handleIsDisable();
        }

        localStorage.removeItem(username + '-DataRV');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (id) => {
        let arrayData = [];
        let rate = 0;
        ApiService.FinAccPostById(paramReceiptVoucher, id)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsEdit(true);
                    rate = response.data.data.receiptVoucher.rate;

                    setRowStatus(response.data.data.receiptVoucher.rowStatus);
                    setTicketId(response.data.data.receiptVoucher.ticketId);
                    setRVNo(response.data.data.receiptVoucher.rvNo);
                    setRVNo2(response.data.data.receiptVoucher.rvNo2);
                    setReference(response.data.data.receiptVoucher.reference);
                    setReceiptBy(response.data.data.receiptVoucher.receiptByCode);
                    setCustomerTo(response.data.data.receiptVoucher.customerToId);
                    setContactId(response.data.data.receiptVoucher.contactId);
                    setContactCode(response.data.data.receiptVoucher.contactCode);
                    setContactName(response.data.data.receiptVoucher.contactName);
                    setCityId(response.data.data.receiptVoucher.cityId);
                    setCityInt(response.data.data.receiptVoucher.cityCode);
                    setCityName(response.data.data.receiptVoucher.cityName);
                    setAddress(response.data.data.receiptVoucher.address);
                    handleDocumentStatus(response.data.data.receiptVoucher.rowStatus, response.data.data.receiptVoucher.isApprovedAcc);
                    setRate(convertToNominal(response.data.data.receiptVoucher.rate));
                    setRateReal(response.data.data.receiptVoucher.rate);
                    setVerifyAcc(response.data.data.receiptVoucher.approvedAccBy);
                    setVerify(response.data.data.receiptVoucher.isApprovedAcc === true ? 'Yes' : 'No');
                    setPrinting(response.data.data.receiptVoucher.printing);
                    setTotalBankUSD(response.data.data.receiptVoucher.totalBankUSD);
                    setTotalBankIDR(response.data.data.receiptVoucher.totalBankIDR);
                    setTotalCashUSD(response.data.data.receiptVoucher.totalCashUSD);
                    setTotalCashIDR(response.data.data.receiptVoucher.totalCashUSD);
                    setDifference(response.data.data.receiptVoucher.difference);
                    setPeriod(response.data.data.receiptVoucher.period);
                    setYearPeriod(response.data.data.receiptVoucher.yearPeriod);
                    setSaveOR(response.data.data.receiptVoucher.saveOR);
                    setPaidPV(response.data.data.receiptVoucher.paidPV);
                    setJobOwnerId(response.data.data.receiptVoucher.jobOwnerId);
                    setReceiptNo2(response.data.data.receiptVoucher.receiptNo2);
                    setExRateId(response.data.data.receiptVoucher.exRateId);
                    setTandaMigrasi(response.data.data.receiptVoucher.tandaMigrasi);
                    setDeletedRemarks(response.data.data.receiptVoucher.deletedRemarks);
                    setWFComplete(response.data.data.receiptVoucher.wfComplete);
                    setDeleted(response.data.data.receiptVoucher.deleted);
                    setIsApprovedAcc(response.data.data.receiptVoucher.isApprovedAcc);

                    setDateFormat(response.data.data.receiptVoucher.exRateDate, setExRateDate);
                    setDateFormat(response.data.data.receiptVoucher.approvedAccOn, setVerifyAccOn);
                    setDateFormat(response.data.data.receiptVoucher.printedOn, setPrintedOn);
                    setDateFormat(response.data.data.receiptVoucher.paidOn, setPaidOn);
                    setDateFormat(response.data.data.receiptVoucher.deletedOn, setDeletedOn);

                    arrayData = response.data.data.receiptVoucher.receiptVoucherDetails;

                    arrayData.forEach(element => {
                        let datePaid = updateDateFormat(element.paidOn);
                        if (datePaid === nullDate) {
                            element.paidOn = defaultDate;
                        }

                        if (element.receiptVoucherDetailBanks.length > 0) {
                            element.receiptVoucherDetailBanks.forEach(el => {
                                let dateDue = updateDateFormat(el.dueDate);
                                if (dateDue === nullDate || dateDue === '') {
                                    el.dueDate = defaultDate;
                                }
                            });
                        }
                    });

                    setDataReceiptVoucher(arrayData);

                    let tempArrayId = [];
                    arrayData.forEach(element => {
                        if (tempArrayId.length > 0) {
                            let checked = 0;
                            tempArrayId.forEach(el => {
                                if (element.refId === el) {
                                    checked = 1;
                                }
                            });

                            if (checked === 0) {
                                tempArrayId = [...tempArrayId, element.refId];
                            }
                        }
                        else {
                            tempArrayId = [...tempArrayId, element.refId];
                        }
                    });
                    setDataReceiptVoucherId(tempArrayId);
                }
                else {
                    Back();
                }
            })
            .then(() => {
                calculateTotalDebitCredit(arrayData, rate);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataApproved = () => {
        let data = {};
        ApiService.CustomFinanceCreate(paramReceiptVoucher + '/Create?check=true', data)
            .then((res) => {
                if (res.data.code === 200 && res.data.data !== null) {
                    let data = res.data.data;

                    if (data != null) {
                        setIsUnapproved(true);

                        if (!receiptVoucherId) {
                            Back();
                        }
                    }
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            });
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false
        let tempArray = [];
        let checked = 0;

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FREV')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }
                        });

                        setCheck(checked);

                        GetJobOwnerData(1, 20);
                        handleDocumentStatus();
                        getDataApproved();
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetJobOwnerData = (pageNumber, pageSize) => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.jobOwner.length > 0) {
                        setJobOwnerId(response.data.data.jobOwner[0].id);
                    }
                    setIsJobOwner(false);
                    setJobOwnerData(response.data.data.jobOwner);

                    GetSelectionData(1, 100);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const selectPrincipleBy = (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={JobOwnerId}
                onChange={(e) => handleCheckJobOwner(e.target.value)}
                disabled={isApprovedAcc}
            >
                {
                    jobOwnerData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetSelectionData = (pageNumber, pageSize) => {
        let params = [];
        params = [
            ...params,
            { 'attr': 'menuCode', 'value': 'RV' }
        ]
        ApiService.DynamicPostByPage(paramSD, 'SEARCH', pageNumber, pageSize, params)
            .then((response) => {
                if (response.data.code === 200) {
                    let tempArr = response.data.data.selectionData;
                    tempArr = tempArr.filter(el => el.name !== 'Personal');
                    setSelectionData(tempArr);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getLocalData(); }, 2000);
                    }
                    else {
                        if (receiptVoucherId) {
                            getData(receiptVoucherId);
                            handleIsDisable();
                        }
                        else {
                            GetExRate();
                        }
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const selectReceiptBy = (ReceiptBy, handleReceiptBy, isDisable) => {
        return (
            <>
                <Selection
                    id='0'
                    label={'Receipt By'}
                    fullWidth={true}
                    placeholder={'Select Receipt By'}
                    value={ReceiptBy}
                    onChange={(e) => handleReceiptBy(e.target.value)}
                    disabled={isDisable}
                >
                    {
                        SelectionData.map((data, index) => (
                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                        ))
                    }
                </Selection>
            </>
        )
    };

    const GetExRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    setExRateId(response.data.data.exchangeRate.id);
                    setRate(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setExRateDate);

                    GetFormatCurrency();
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const GetFormatCurrency = () => {
        let totalUSD = 0;
        let totalIDR = 0;
        let formatUSD = convertToNominal(totalUSD);
        let formatIDR = convertToNominal(totalIDR);

        setTotalCreditUSD(formatUSD);
        setTotalCreditIDR(formatIDR);
        setTotalCreditInIDR(formatIDR);
        setTotalDebitUSD(formatUSD);
        setTotalDebitIDR(formatIDR);
        setTotalDebitInIDR(formatIDR);
        setTotalDifference(formatIDR);

        if (!dataLocal) {
            setIsLoadingBackDrop(false);
        }
    }

    function Save(isDeleted, isPrint = false, result) {
        if (isLoadingBackDrop) return
        if (isDeleted === false) {
            setIsLoadingBackDrop(true);
        }

        let id = receiptVoucherId ? receiptVoucherId : 0;
        let printDate = updateDateFormat(PrintedOn, true);
        let verifyDate = updateDateFormat(VerifyAccOn, true);
        let deleteDate = updateDateFormat(DeletedOn, true);
        let paidDate = updateDateFormat(PaidOn, true);
        let arrayData = [];
        let totalBankUSD = 0;
        let totalBankIDR = 0;
        let totalCashUSD = 0;
        let totalCashIDR = 0;
        let totalDiff = 0;

        arrayData = DataReceiptVoucher;
        totalBankUSD = TotalBankUSD;
        totalBankIDR = TotalBankIDR;
        totalCashUSD = TotalCashUSD;
        totalCashIDR = TotalCashIDR;
        totalDiff = TotalDifferenceReal;

        let receiptByName = '';
        SelectionData.forEach(element => {
            if (element.code === ReceiptBy) {
                receiptByName = element.name;
            }
        });

        let paymentToName = '';
        let dataPaymentTo = [
            { id: 1, code: 2, name: 'Shipper' },
            { id: 2, code: 3, name: 'Consigee' },
            { id: 3, code: 1, name: 'Agent' },
            { id: 4, code: 4, name: 'SSLine' },
            { id: 5, code: 6, name: 'EMKL' },
            { id: 6, code: 11, name: 'Branch' },
            { id: 7, code: 5, name: 'IATA' },
            { id: 8, code: 7, name: 'Depo' },
            { id: 9, code: 10, name: 'Trucking' },
            { id: 10, code: 12, name: 'Personal' },
            { id: 11, code: 0, name: 'None' },
        ];

        dataPaymentTo.forEach(element => {
            if (element.code === CustomerTo) {
                paymentToName = element.name;
            }
        });

        let RVDetail = arrayData;
        RVDetail.forEach(element => {
            let datePaid = updateDateFormat(element.paidOn);

            if (datePaid === nullDate) {
                element.paidOn = defaultDate;
            }

            let RVDetailBank = element.receiptVoucherDetailBanks;

            RVDetailBank.forEach(el => {
                let dateDue = updateDateFormat(el.dueDate);

                if (dateDue === nullDate) {
                    el.dueDate = defaultDate;
                }
            });

            element.receiptVoucherDetailBanks = RVDetailBank;
        });

        var data = {
            "receiptVoucher": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "id": id,
                "ticketId": TicketId,
                "rvNo": RVNo,
                "rvNo2": RVNo2,
                "reference": Reference,
                "receiptByCode": ReceiptBy,
                "receiptByName": receiptByName,
                "customerToId": CustomerTo,
                "customerToName": paymentToName,
                "contactId": ContactId,
                "contactCode": ContactCode,
                "contactName": ContactName,
                "cityId": CityId,
                "cityCode": CityInt,
                "cityName": CityName,
                "address": Address,
                "rate": RateReal,
                "exRateDate": ExRateDate,
                "approvedAccBy": VerifyAcc,
                "approvedAccOn": verifyDate,
                "printing": Printing,
                "printedOn": printDate,
                "totalBankUSD": totalBankUSD,
                "totalBankIDR": totalBankIDR,
                "totalCashUSD": totalCashUSD,
                "totalCashIDR": totalCashIDR,
                "difference": totalDiff,
                "period": Period,
                "yearPeriod": YearPeriod,
                "saveOR": SaveOR,
                "paidPV": PaidPV,
                "paidOn": paidDate,
                "jobOwnerId": JobOwnerId,
                "receiptNo2": ReceiptNo2,
                "exRateId": ExRateId,
                "tandaMigrasi": TandaMigrasi,
                "deletedRemarks": DeletedRemarks,
                "wfComplete": WFComplete,
                "deletedOn": deleteDate,
                "deleted": Deleted,
                "isApprovedAcc": isApprovedAcc,

                "idLama": 0,
            },
            "receiptVoucherDetails": RVDetail,
        };

        let message = '';
        let check = 0;

        if (data.receiptVoucherDetails.length > 0 && totalDiff === 0) {
            check = 1;
        }

        if (check === 1) {
            ApiService.HelpCreateData(data, paramReceiptVoucher, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        if (id === 0) {
                            ApiService.CreateFinAcc(paramReceiptVoucher, data).then((res) => {
                                if (res.data.code === 200) {
                                    if (isDeleted === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Create Data Success';

                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                                history('/Majura/ReceiptVoucher/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                                window.location.reload();
                                                localStorage.removeItem('tempId');
                                            },
                                            1250);
                                    }
                                }
                                else {
                                    if (isDeleted === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Create Data Failed';

                                        showToast({ type: 'error', message: message });
                                    }
                                }
                            });
                        }
                        else {
                            ApiService.EditFinAcc(paramReceiptVoucher, data).then((res) => {
                                if (res.data.code === 200) {
                                    if (isDeleted === false && isPrint === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Update Data Success';

                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                history('/Majura/ReceiptVoucher/' + receiptVoucherId, { replace: true });
                                                window.location.reload();
                                            },
                                            1250);
                                    }
                                    else if (isPrint) {
                                        Print();
                                    }
                                }
                                else {
                                    if (isDeleted === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Update Data Failed';

                                        showToast({ type: 'error', message: message });
                                    }
                                }
                            });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);

                        showToast({ type: 'error', message: 'Failed to Create Help Data!' });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);

                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator!' });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            if (totalDiff !== 0) {
                showToast({ type: 'error', message: 'Receipt Voucher Must Be Balanced!' });
            }
            else {
                showToast({ type: 'error', message: 'Please Add Receive Voucher Detail!' });
            }
        }
    }

    function Back() {
        let check = 0;
        if (isEdit === true) {
            if (DataReceiptVoucher.length === 0) {
                check = 1;
            }
        }

        if (check === 0) {
            history('/Majura/ReceiptVoucher');
        }
        else {
            showToast({ type: 'error', message: 'Please Add Receipt Voucher Detail!' });
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
            setIsDueDate(false);
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    function ConfirmationRV() {
        if (titleConfirmation === 'Print') {
            if (isApprovedAcc === false) {
                Save(false, true);
            }
            else {
                Print();
            }
        }
        else if (titleConfirmation === 'Approve') {
            Approve();
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        if (receiptVoucherId) {
            setIsLoadingBackDrop(true);
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Receipt Voucher');

            if (Printing === 0) {
                getDateNow();
            }
            else {
                setDateFormat(PrintedOn, setDueDate);
            }

            if (TotalCashIDR === 0 && TotalCashUSD === 0) {
                setIsDueDate(true);
            }

            getBackDatePeriod()
                .then((backDate) => {
                    setBackDate(backDate);
                    handleClickConfirmation();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoadingBackDrop(false);
                });
        }
        else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    const Print = () => {
        setIsLoadingBackDrop(true);
        let dateDue = dueDate === '' ? updateDateFormat(new Date()) : dueDate;
        ApiService.FinAccBackDatePrint(paramReceiptVoucher, receiptVoucherId, dateDue).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                let count = parseInt(Printing) + parseInt(1);
                setPrinting(count);

                PrintPDF();
                setIsDueDate(false);
            }
            else {
                setIsDueDate(false);
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: message });
            }
        });
    };

    const PrintPDF = () => {
        let variable = 'PrintRV?Id=' + receiptVoucherId;
        ApiService.ReportPrint('Transactions/Finance/ReceiptVoucherReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Receipt Voucher Success!' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Receipt Voucher!' });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Receipt Voucher!' });
            });
    }

    const CreateGL = (id) => {
        var data = {
            "generalLedger": {
                "source": "RV",
                "refId": id,
                "methodPost": "AUTO",
                "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
            }
        }

        ApiService.CreateFinAcc(paramGL, data).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code !== 200) {
                showToast({ type: 'error', message: message });
            }
        });
    }

    function AddNew() {
        history('/Majura/ReceiptVoucher/Create', { replace: true });
        window.location.reload();
    }

    const ApproveData = () => {
        if (receiptVoucherId) {
            if (Printing > 0) {
                if (isApprovedAcc === false) {
                    setTitleConfirmation('Approve')
                    setTitleModalConfirmation('Receipt Voucher');
                    handleClickConfirmation();
                }
                else {
                    showToast({ type: 'error', message: 'Data Already Been Approved!' });
                }
            }
            else {
                showToast({ type: 'error', message: 'Please Print The Data First!' });
            }
        }
        else {
            showToast({ type: 'error', message: 'Please Create The Data First!' });
        }
    }

    const Approve = () => {
        setIsLoadingBackDrop(true);
        let check = 0;

        DataReceiptVoucher.forEach(element => {
            if (element.coaCode === '') {
                check = 1;
            }
        });

        if (check === 0) {
            ApiService.FinAccUpdateStatusApprovedAcc(paramReceiptVoucher, receiptVoucherId).then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    CreateGL(receiptVoucherId);

                    showToast({ type: 'success', message: message });

                    setTimeout(
                        function () {
                            history('/Majura/ReceiptVoucher');
                        },
                        1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                }
            })
                .catch(error => {
                    setIsLoadingBackDrop(false);
                    console.log(error);
                    showToast({ type: 'error', message: 'Can\'t Approve Receipt Voucher!' });
                });
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Some Detail Didn\'t Have COA, Please Complete It First Before Approve The Data!' });
        }
    }

    function checkInput() {
        let check = 0;
        if (parseFloat(CustomerTo) !== 0) {
            if (ContactId === 0) {
                showToast({ type: 'error', message: 'Please Select Customer First!' });
                check = 1;
            }
        }
        else {
            if (Address === '') {
                showToast({ type: 'error', message: 'Please Input Address First!' });
                check = 1;
            }
        }
        return check;
    }

    function handleIsDisable() {
        setIsDisable(true);
        setIsNot(true);
        setIsYes(true);
    }

    function calculateTotalDebitCredit(result, rate) {
        let totalCreditUSD = 0;
        let totalCreditIDR = 0;
        let totalCreditInIDR = 0;

        let totalCreditCashUSD = 0;
        let totalCreditCashIDR = 0;

        let totalCreditBankUSD = 0;
        let totalCreditBankIDR = 0;

        let totalDebitUSD = 0;
        let totalDebitIDR = 0;
        let totalDebitInIDR = 0;

        let totalDebitCashUSD = 0;
        let totalDebitCashIDR = 0;

        let totalDebitBankUSD = 0;
        let totalDebitBankIDR = 0;

        let totalCashUSD = 0;
        let totalCashIDR = 0;

        let totalBankUSD = 0;
        let totalBankIDR = 0;

        let totalUSD = 0;
        let totalIDR = 0;
        let totalInIDR = 0;

        let totalDifference = 0;

        for (let i = 0; i < result.length; i++) {
            if (result[i].dcNote === 'C') {
                totalCreditUSD = parseFloat(result[i].amountUSD) + parseFloat(totalCreditUSD);
                totalCreditIDR = parseFloat(result[i].amountIDR) + parseFloat(totalCreditIDR);
                totalCreditInIDR = (parseFloat(result[i].amountUSD) * parseFloat(result[i].refRate)) + parseFloat(result[i].amountIDR) + parseFloat(totalCreditInIDR);

                totalCreditCashUSD = parseFloat(result[i].receiptCashUSD) + parseFloat(totalCreditCashUSD);
                totalCreditCashIDR = parseFloat(result[i].receiptCashIDR) + parseFloat(totalCreditCashIDR);

                for (let j = 0; j < result[i].receiptVoucherDetailBanks.length; j++) {
                    if (result[i].receiptVoucherDetailBanks[j].amountCrr === 0) {
                        totalCreditBankUSD = parseFloat(result[i].receiptVoucherDetailBanks[j].amount) + parseFloat(totalCreditBankUSD);
                    }
                    else {
                        totalCreditBankIDR = parseFloat(result[i].receiptVoucherDetailBanks[j].amount) + parseFloat(totalCreditBankIDR);
                    }
                }
            }
            else if (result[i].dcNote === 'D') {
                totalDebitUSD = parseFloat(result[i].amountUSD) + parseFloat(totalDebitUSD);
                totalDebitIDR = parseFloat(result[i].amountIDR) + parseFloat(totalDebitIDR);
                totalDebitInIDR = (parseFloat(result[i].amountUSD) * parseFloat(result[i].refRate)) + parseFloat(result[i].amountIDR) + parseFloat(totalDebitInIDR);

                totalDebitCashUSD = parseFloat(result[i].receiptCashUSD) + parseFloat(totalDebitCashUSD);
                totalDebitCashIDR = parseFloat(result[i].receiptCashIDR) + parseFloat(totalDebitCashIDR);

                for (let j = 0; j < result[i].receiptVoucherDetailBanks.length; j++) {
                    if (result[i].receiptVoucherDetailBanks[j].amountCrr === 0) {
                        totalDebitBankUSD = parseFloat(result[i].receiptVoucherDetailBanks[j].amount) + parseFloat(totalDebitBankUSD);
                    }
                    else {
                        totalDebitBankIDR = parseFloat(result[i].receiptVoucherDetailBanks[j].amount) + parseFloat(totalDebitBankIDR);
                    }
                }
            }
        }

        totalCashUSD = parseFloat(totalCreditCashUSD) + parseFloat(totalDebitCashUSD);
        totalCashIDR = parseFloat(totalCreditCashIDR) + parseFloat(totalDebitCashIDR);

        setTotalCashUSD(totalCashUSD);
        setTotalCashIDR(totalCashIDR);

        totalBankUSD = parseFloat(totalCreditBankUSD) + parseFloat(totalDebitBankUSD);
        totalBankIDR = parseFloat(totalCreditBankIDR) + parseFloat(totalDebitBankIDR);

        setTotalBankUSD(totalBankUSD);
        setTotalBankIDR(totalBankIDR);

        setTotalCreditUSD(convertToNominal(totalCreditUSD));
        setTotalCreditIDR(convertToNominal(totalCreditIDR));
        setTotalCreditInIDR(convertToNominal(totalCreditInIDR));

        totalUSD = parseFloat(totalDebitUSD) + parseFloat(totalDebitCashUSD) + parseFloat(totalDebitBankUSD) + parseFloat(totalCreditCashUSD) + parseFloat(totalCreditBankUSD);
        totalIDR = parseFloat(totalDebitIDR) + parseFloat(totalDebitCashIDR) + parseFloat(totalDebitBankIDR) + parseFloat(totalCreditCashIDR) + parseFloat(totalCreditBankIDR);
        totalInIDR = parseFloat(totalDebitInIDR) +
            ((parseFloat(totalDebitCashUSD) + parseFloat(totalDebitBankUSD) + parseFloat(totalCreditCashUSD) + parseFloat(totalCreditBankUSD)) * parseFloat(rate)) +
            parseFloat(totalDebitCashIDR) + parseFloat(totalDebitBankIDR) + parseFloat(totalCreditCashIDR) + parseFloat(totalCreditBankIDR);

        setTotalDebitUSD(convertToNominal(totalUSD));
        setTotalDebitIDR(convertToNominal(totalIDR));
        setTotalDebitInIDR(convertToNominal(totalInIDR));

        totalDifference = Math.abs(parseFloat(totalInIDR) - parseFloat(totalCreditInIDR));
        setTotalDifferenceReal(totalDifference);
        setTotalDifference(convertToNominal(totalDifference));
        if (totalDifference === 0) {
            setLabelDifference('Balanced');
        }
        else {
            setLabelDifference('Exchange Rate Difference (Credit)');
        }
    }

    function handleCheckJobOwner(val) {
        setJobOwnerId(val);
        if (isEdit === false) {
            if (val !== undefined || val !== 0 || val !== null || val !== '') {
                setIsJobOwner(false);
            }
            else {
                setIsJobOwner(true);
            }
        }
        else {
            setIsJobOwner(true);
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditReceiptVoucher
                Save={Save}
                Back={Back}
                Print={PrintData}
                AddNew={AddNew}
                Approve={ApproveData}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isEdit={isEdit}
                isApprovedAcc={isApprovedAcc}
                isUnapproved={isUnapproved}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            receiptVoucherId ?
                                'Receipt Voucher (' + Reference + ')'
                                :
                                'Receipt Voucher ( New Document )'
                        }
                        BreadcrumbsItems={receiptVoucherId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={TicketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <ReceiptVoucherHeader
                                selectPrincipleBy={selectPrincipleBy}
                                isJobOwner={isJobOwner}
                                selectReceiptBy={selectReceiptBy}
                                RVNo={RVNo2}
                                setRVNo={setRVNo}
                                isApprovedAcc={isApprovedAcc}
                                isEdit={isEdit}
                                Reference={Reference}
                                setReference={setReference}
                                ReceiptBy={ReceiptBy}
                                setReceiptBy={setReceiptBy}
                                CustomerTo={CustomerTo}
                                setCustomerTo={setCustomerTo}
                                ContactId={ContactId}
                                setContactId={setContactId}
                                ContactCode={ContactCode}
                                setContactCode={setContactCode}
                                ContactName={ContactName}
                                setContactName={setContactName}
                                CityId={CityId}
                                setCityId={setCityId}
                                CityInt={CityInt}
                                setCityInt={setCityInt}
                                CityName={CityName}
                                setCityName={setCityName}
                                Address={Address}
                                setAddress={setAddress}
                                Rate={Rate}
                                ExRateDate={ExRateDate}
                                Verify={Verify}
                                PrintedOn={PrintedOn}
                                setDataReceiptVoucher={setDataReceiptVoucher}
                                setSelectedReceiptVoucher={setSelectedReceiptVoucher}
                                setDataReceiptVoucherId={setDataReceiptVoucherId}
                                setBankData={setBankData}
                                VerifyAccOn={VerifyAccOn}
                                setCashPaymentUSD={setCashPaymentUSD}
                                setCashPaymentIDR={setCashPaymentIDR}
                                calculateTotalDebitCredit={calculateTotalDebitCredit}
                                SelectionData={SelectionData}
                                isDisable={isDisable}
                                setIsDisable={setIsDisable}
                                isNot={isNot}
                                setIsNot={setIsNot}
                                isYes={isYes}
                                setIsYes={setIsYes}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <ReceiptVoucherDetail
                                CustomerTo={CustomerTo}
                                Rate={Rate}
                                RVNo={RVNo2}
                                RateReal={RateReal}
                                setSelectedReceiptVoucher={setSelectedReceiptVoucher}
                                receiptVoucherId={receiptVoucherId}
                                ContactId={ContactId}
                                setBankData={setBankData}
                                checkInput={checkInput}
                                ExRateId={ExRateId}
                                setCashPaymentUSD={setCashPaymentUSD}
                                setCashPaymentIDR={setCashPaymentIDR}
                                DataReceiptVoucher={DataReceiptVoucher}
                                setDataReceiptVoucher={setDataReceiptVoucher}
                                DataReceiptVoucherId={DataReceiptVoucherId}
                                setDataReceiptVoucherId={setDataReceiptVoucherId}
                                ContactName={ContactName}
                                ReceiptBy={ReceiptBy}
                                Save={Save}
                                calculateTotalDebitCredit={calculateTotalDebitCredit}
                                isApprovedAcc={isApprovedAcc}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <ReceiptVoucherFor
                                tabValue={tabValue}
                                setTabValue={setTabValue}
                                Rate={RateReal}
                                selectedReceiptVoucher={selectedReceiptVoucher}
                                receiptVoucherId={receiptVoucherId}
                                DataReceiptVoucher={DataReceiptVoucher}
                                setDataReceiptVoucher={setDataReceiptVoucher}
                                BankData={BankData}
                                setBankData={setBankData}
                                isApprovedAcc={isApprovedAcc}
                                calculateTotalDebitCredit={calculateTotalDebitCredit}
                                CashPaymentUSD={CashPaymentUSD}
                                setCashPaymentUSD={setCashPaymentUSD}
                                CashPaymentIDR={CashPaymentIDR}
                                setCashPaymentIDR={setCashPaymentIDR}
                                TotalCashUSD={TotalCashUSD}
                                setTotalCashUSD={setTotalCashUSD}
                                TotalCashIDR={TotalCashIDR}
                                setTotalCashIDR={setTotalCashIDR}
                                TotalBankUSD={TotalBankUSD}
                                setTotalBankUSD={setTotalBankUSD}
                                TotalBankIDR={TotalBankIDR}
                                setTotalBankIDR={setTotalBankIDR}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <ReceiptVoucherTotalReceipt
                                TotalDebitUSD={TotalDebitUSD}
                                setTotalDebitUSD={setTotalDebitUSD}
                                TotalDebitIDR={TotalDebitIDR}
                                setTotalDebitIDR={setTotalDebitIDR}
                                TotalDebitInIDR={TotalDebitInIDR}
                                setTotalDebitInIDR={setTotalDebitInIDR}
                                TotalCreditUSD={TotalCreditUSD}
                                setTotalCreditUSD={setTotalCreditUSD}
                                TotalCreditIDR={TotalCreditIDR}
                                setTotalCreditIDR={setTotalCreditIDR}
                                TotalCreditInIDR={TotalCreditInIDR}
                                setTotalCreditInIDR={setTotalCreditInIDR}
                                TotalDifference={TotalDifference}
                                setTotalDifference={setTotalDifference}
                                LabelDifference={LabelDifference}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationRV}
                            isDueDate={isDueDate}
                            setIsDueDate={setIsDueDate}
                            dueDate={dueDate}
                            backDate={backDate}
                            setDueDate={setDueDate}
                            PrintCount={Printing}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditReceiptVoucher;