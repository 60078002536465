import { Grid, Stack, IconButton, MenuItem, InputAdornment, } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ApiService from '../../../../../services/ApiService';
import SelectDataContact from '../Selects/SelectDataContact';
import SelectDataNonContact from '../Selects/SelectDataNonContact';
import { Icon, Input, Selection, showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks';

const PaymentVoucherHeader = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isShipper, setIsShipper] = useState(false);
    const [isConsignee, setIsConsignee] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [isSSLine, setIsSSLine] = useState(false);
    const [isEMKL, setIsEMKL] = useState(false);
    const [isBranch, setIsBranch] = useState(false);
    const [isIATA, setIsIATA] = useState(false);
    const [isDepo, setIsDepo] = useState(false);
    const [isPersonal, setIsPersonal] = useState(false);
    const [isTrucking, setIsTrucking] = useState(false);
    const [isNone, setIsNone] = useState(false);
    const [isSelectTrucking, setIsSelectTrucking] = useState(true);

    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [dataMapC, setDataMapC] = useState([])
    const [columnDataC, setColumnDataC] = useState([])

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataContact(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchContact = (data) => {
        if (data === 'search') {
            setTitleModal('Contact');
            handleClick();
        }
        else if (data === 'delete') {
            props.setContactId(0);
            props.setContactCode('');
            props.setContactName('');

            props.setSelectedPaymentVoucher({});
            props.setDataPaymentDetail([]);
            props.setDataPaymentDetailId([]);
            props.setBankData([]);
            props.setIsAllPPh(false);
            props.setCashPayment(0);

            props.calculateTotalDebitCredit([], props.Rate, props.PaymentBy, 0);
        }
    }

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);

    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);
    const [dataMapN, setDataMapN] = useState([])
    const [columnDataN, setColumnDataN] = useState([])

    const handleClick2 = (title) => {
        if (isModal2 === false) {
            setIsModal2(true);
            localStorage.setItem("title", JSON.stringify(title));
            getDataNonContact(1, 50);
        }
        else {
            setIsModal2(false);
            localStorage.removeItem("title");
        }
    };

    const searchBank = (data) => {
        if (data === 'search') {
            setTitleModal2('Bank');
            handleClick2('Bank');
        }
        else if (data === 'delete') {
            props.setBankId(0);
            props.setBankCode('');
            props.setBankIntName('');
            props.setBankName('');
        }
    };

    const searchNoPolisi = (data) => {
        if (data === 'search') {
            setTitleModal2('Kendaraan');
            handleClick2('Kendaraan');
        }
        else if (data === 'delete') {
            props.setNoPolisi('');
            props.setKendaraanId(0);
        }
    };

    const dataPaymentBy = [
        { id: 1, code: 'CI', name: 'Cash IDR' },
        { id: 2, code: 'CU', name: 'Cash USD' },
        { id: 3, code: 'BI', name: 'Bank IDR' },
        { id: 4, code: 'BU', name: 'Bank USD' },
    ];

    const dataPaymentTo = [
        { id: 1, code: 8, name: 'Shipper', disabled: isShipper },
        { id: 2, code: 9, name: 'Consigee', disabled: isConsignee },
        { id: 3, code: 1, name: 'Agent', disabled: isAgent },
        { id: 4, code: 4, name: 'SSLine', disabled: isSSLine },
        { id: 5, code: 6, name: 'EMKL', disabled: isEMKL },
        { id: 6, code: 11, name: 'Branch', disabled: isBranch },
        { id: 7, code: 5, name: 'IATA', disabled: isIATA },
        { id: 8, code: 7, name: 'Depo', disabled: isDepo },
        { id: 9, code: 12, name: 'Personal', disabled: isPersonal },
        { id: 10, code: 10, name: 'Trucking', disabled: isTrucking },
        { id: 11, code: 0, name: 'None', disabled: isNone },
    ];

    const dataInclude = [
        { id: 1, code: true, name: 'Yes' },
        { id: 2, code: false, name: 'No' },
    ];

    const dataPercent = [
        { id: 1, code: 2, name: '2%' },
        { id: 2, code: 4, name: '4%' },
        { id: 3, code: 15, name: '15%' },
    ]

    function handlePaymentToSelect(val) {
        let check = 0;

        props.setSettlementTo(val);

        props.SelectionData.forEach(element => {
            if (element.code === val) {
                props.setSettlementToName(element.name);
            }
        });

        props.setContactId(0);
        props.setContactCode('');
        props.setContactName('');
        props.setCityId(0);
        props.setCityInt('');
        props.setCityName('');
        props.setAddress('');
        props.setNoPolisi('');
        props.setKendaraanId(0);

        props.setSelectedPaymentVoucher({});
        props.setDataPaymentDetail([]);
        props.setDataPaymentDetailId([]);
        props.setIsAllPPh(false);
        props.setBankData([]);
        props.setCashPayment(0);

        props.calculateTotalDebitCredit([], props.Rate, props.PaymentBy, 0);

        if (val === 'I') {
            check = 1;
            props.setCustomerTo(1);
            props.setIsNot(false);
            props.setIsYes(true);
        }
        else if (val === 'P') {
            check = 2;
            props.setCustomerTo(4);
            props.setIsNot(false);
            props.setIsYes(true);
        }
        else if (val === 'T') {
            check = 3;
            props.setCustomerTo(8);
            props.setIsNot(false);
            props.setIsYes(true);
        }
        else if (val === 'R') {
            check = 4;
            props.setCustomerTo(8);
            props.setIsNot(false);
            props.setIsYes(true);
        }
        else {
            check = 0;
            props.setCustomerTo(0);
            props.setIsNot(true);
            props.setIsYes(false);
        }

        for (let i = 0; i < dataPaymentTo.length; i++) {
            if (check === 0) {
                setIsShipper(false);
                setIsConsignee(false);
                setIsAgent(false);
                setIsSSLine(false);
                setIsEMKL(false);
                setIsBranch(false);
                setIsIATA(false);
                setIsDepo(false);
                setIsPersonal(false);
                setIsTrucking(false);
                setIsNone(false);
            }
            else if (check === 1) {
                if (dataPaymentTo[i].name !== 'Agent') {
                    setIsShipper(true);
                    setIsConsignee(true);
                    setIsAgent(false);
                    setIsSSLine(true);
                    setIsEMKL(true);
                    setIsBranch(true);
                    setIsIATA(true);
                    setIsDepo(true);
                    setIsPersonal(true);
                    setIsTrucking(true);
                    setIsNone(true);
                }
            }
            else if (check === 2) {
                if (dataPaymentTo[i].name === 'Agent' || dataPaymentTo[i].name === 'Branch'
                    || dataPaymentTo[i].name === 'Personal' || dataPaymentTo[i].name === 'None') {
                    setIsShipper(false);
                    setIsConsignee(false);
                    setIsAgent(true);
                    setIsSSLine(false);
                    setIsEMKL(false);
                    setIsBranch(true);
                    setIsIATA(false);
                    setIsDepo(false);
                    setIsPersonal(true);
                    setIsTrucking(false);
                    setIsNone(true);
                }
            }
            else if (check === 3) {
                if (dataPaymentTo[i].name !== 'Shipper' || dataPaymentTo[i].name !== 'Consignee') {
                    setIsShipper(false);
                    setIsConsignee(false);
                    setIsAgent(true);
                    setIsSSLine(true);
                    setIsEMKL(true);
                    setIsBranch(true);
                    setIsIATA(true);
                    setIsDepo(true);
                    setIsPersonal(true);
                    setIsTrucking(true);
                    setIsNone(true);
                }
            }
            else if (check === 4) {
                if (dataPaymentTo[i].name === 'Trucking' || dataPaymentTo[i].name === 'None') {
                    setIsShipper(false);
                    setIsConsignee(false);
                    setIsAgent(false);
                    setIsSSLine(false);
                    setIsEMKL(false);
                    setIsBranch(false);
                    setIsIATA(false);
                    setIsDepo(false);
                    setIsPersonal(false);
                    setIsTrucking(true);
                    setIsNone(true);
                }
            }
        }
    }

    function handlePaymentTo(val) {
        props.setCustomerTo(val);
        props.setContactId(0);
        props.setContactCode('');
        props.setContactName('');
        props.setCityId(0);
        props.setCityInt('');
        props.setCityName('');
        props.setAddress('');
        props.setNoPolisi('');
        props.setKendaraanId(0);

        props.setSelectedPaymentVoucher({});
        props.setDataPaymentDetail([]);
        props.setDataPaymentDetailId([]);
        props.setIsAllPPh(false);
        props.setBankData([]);
        props.setCashPayment(0);

        props.setIsPPh23(false);
        props.setPPh23Percent(2);
        props.setIsInclude(true);
        props.setPPh23Amount(convertToNominal(0));
        props.setPPh23AmountReal(0);
        props.setPPh23Remarks('');

        props.calculateTotalDebitCredit([], props.Rate, props.PaymentBy, 0);

        if (parseInt(val) === 0) {
            props.setIsNot(true);
            props.setIsYes(false);
        }
        else {
            if (parseInt(val) === 10) {
                setIsSelectTrucking(false);
            }
            else {
                setIsSelectTrucking(true);
            }
            props.setIsNot(false);
            props.setIsYes(true);
        }
    };

    function handlePaymentBy(val) {
        props.setPaymentBy(val);
        props.setBankId(0);
        props.setBankCode('');
        props.setBankIntName('');
        props.setBankName('');
        props.checkCash(val);

        props.setCustomerTo(val);
        props.setContactId(0);
        props.setContactCode('');
        props.setContactName('');
        props.setCityId(0);
        props.setCityInt('');
        props.setCityName('');
        props.setAddress('');
        props.setNoPolisi('');
        props.setKendaraanId(0);

        props.setSelectedPaymentVoucher({});
        props.setDataPaymentDetail([]);
        props.setDataPaymentDetailId([]);
        props.setIsAllPPh(false);
        props.setBankData([]);
        props.setCashPayment(0);

        props.setIsPPh23(false);
        props.setPPh23Percent(2);
        props.setIsInclude(true);
        props.setPPh23Amount(convertToNominal(0));
        props.setPPh23AmountReal(0);
        props.setPPh23Remarks('');

        props.calculateTotalDebitCredit([], props.Rate, val, 0);

        if (val === 'BI' || val === 'BU') {
            props.setIsBank(false);
        }
        else {
            props.setIsBank(true);
        }

        handlePaymentToSelect('G');
    };

    function handleInclude(val) {
        if (val === true || val === 'true') {
            props.setIsPPh23(true);
            props.setPPh23Percent(2);
            props.setIsInclude(false);
            props.setIsAllPPh(false);
        }
        else {
            props.setIsPPh23(false);
            props.setPPh23Percent(2);
            props.setIsInclude(true);
            props.setPPh23Amount(convertToNominal(0));
            props.setPPh23AmountReal(0);
            props.setIsAllPPh(false);
            props.setPPh23Remarks('');

            if (props.isInput === true) {
                props.setTempAmount(0);
                props.setIsInput(false);

                props.setIsAllPPh(false);
            }

            let newArr = props.DataPaymentDetail;
            newArr.forEach((el) => {
                el.isPPh23 = false;
            });
            props.setDataPaymentDetail(newArr);
        }

        props.calculateTotalDebitCredit(props.DataPaymentDetail, props.Rate, props.PaymentBy, 0);
    }

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        var type = props.CustomerTo;
        let param = '';
        if (type != null && type !== '') {
            if (props.SettlementTo === 'G') {
                if (parseInt(type) <= 10) {
                    param = 'regContact/regContact';
                    ApiService.ContactPostByPage(param, type, pageNumber, pageSize, filters)
                        .then((response) => {
                            if (response.data.code === 200) {
                                setSelectedDataC({})
                                setDataC(response.data.data.contact);

                                let temp = response.data.data.contact
                                let indexed = temp.map((el, index) => {
                                    let indexedTemp = {
                                        ...el,
                                        index
                                    }
                                    return indexedTemp
                                })

                                setDataMapC(indexed)
                                setColumnDataC(response.data.data.columns)
                                setTotalRowsC(response.data.totalRowCount)

                                setIsLoadingC(false)
                            }
                            setIsLoadingC(false)
                        })
                        .catch(function (error) {
                            setIsLoadingC(false)
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() });
                        })
                }
                else if (parseInt(type) === 11) {
                    param = 'branch/branch';
                    ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters)
                        .then((response) => {
                            if (response.data.code === 200) {
                                setSelectedDataC({})
                                setDataC(response.data.data.branch);

                                let temp = response.data.data.branch
                                let indexed = temp.map((el, index) => {
                                    let indexedTemp = {
                                        ...el,
                                        index
                                    }
                                    return indexedTemp
                                })

                                setDataMapC(indexed)
                                setColumnDataC(response.data.data.columns)
                                setTotalRowsC(response.data.totalRowCount)

                                setIsLoadingC(false)
                            }
                            setIsLoadingC(false)
                        })
                        .catch(function (error) {
                            setIsLoadingC(false)
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() });
                        })
                }
                else if (parseInt(type) === 12) {
                    param = 'employee/employee';
                    ApiService.PostByPage(param, pageNumber, pageSize, filters)
                        .then((response) => {
                            if (response.data.code === 200) {
                                setSelectedDataC({})
                                setDataC(response.data.data.employees);

                                let temp = response.data.data.employees
                                let indexed = temp.map((el, index) => {
                                    let indexedTemp = {
                                        ...el,
                                        index
                                    }
                                    return indexedTemp
                                })

                                setDataMapC(indexed)
                                setColumnDataC(response.data.data.columns)
                                setTotalRowsC(response.data.totalRowCount)

                                setIsLoadingC(false)
                            }
                            setIsLoadingC(false)
                        })
                        .catch(function (error) {
                            setIsLoadingC(false)
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() });
                        })
                }
            }
            else if (props.SettlementTo === 'I') {
                let params = [];
                params = [
                    ...params,
                    { 'attr': 'customerTypeId', 'value': '' + type, 'isDistinct': true },
                    { 'attr': 'customerId', 'value': '', 'isDistinct': true },
                    { 'attr': 'paid', 'value': 'false' },
                    { 'attr': 'isAllPV', 'value': 'false' },
                    { 'attr': 'isPosted', 'value': 'true' },
                    { 'attr': props.PaymentBy === 'BU' || props.PaymentBy === 'CU' ? 'totalPaymentUSD' : 'totalPaymentIDR', 'value': '0', 'mathSign': '>' }
                ];
                param = 'invoice/invoice';

                ApiService.OperationDynamicPostByPage(param, 'SEACON', pageNumber, pageSize, params, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.invoices);

                            let temp = response.data.data.invoices
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            setColumnDataC(response.data.data.columns.headerColumns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        showToast({ type: 'error', message: error.toString() });
                    })
            }
            else if (props.SettlementTo === 'P') {
                let params = [];
                params = [
                    ...params,
                    {
                        'attr': 'customerTypeId',
                        'value': '' + type,
                        'isDistinct': true
                    },
                    {
                        'attr': 'customerId',
                        'value': '',
                        'isDistinct': true
                    },
                    { 'attr': 'isAllPV', 'value': 'false' },
                    { 'attr': 'isPosted', 'value': 'true' },
                    { 'attr': 'approveAcc', 'value': 'true' },
                    { 'attr': 'prContraStatus', 'value': '' },
                    { 'attr': props.PaymentBy === 'BU' || props.PaymentBy === 'CU' ? 'paymentUSD' : 'paymentIDR', 'value': '0', 'mathSign': '>' }
                ];
                param = 'paymentRequest/paymentRequest';

                ApiService.OperationDynamicPostByPage(param, 'SEACON', pageNumber, pageSize, params, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.paymentRequests);

                            let temp = response.data.data.paymentRequests
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            setColumnDataC(response.data.data.columns.headerColumns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        showToast({ type: 'error', message: error.toString() });
                    })
            }
            else if (props.SettlementTo === 'T') {
                let params = [];
                params = [
                    ...params,
                    { 'attr': 'contactTypeId', 'value': type === 8? '2' : type === 9? '3' : '' + type, 'isDistinct': true },
                    { 'attr': 'isDP', 'value': 'false' },
                    { 'attr': 'contactId', 'value': '', 'isDistinct': true },
                    { 'attr': 'isAllPV', 'value': 'false' },
                    { 'attr': 'isApprovedAcc', 'value': 'true' }
                ];
                param = 'temporaryReceipt/temporaryReceipt';

                ApiService.FinAccPostByPage(param, 'SEACON', pageNumber, pageSize, params, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.temporaryReceipts);

                            let temp = response.data.data.temporaryReceipts
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            setColumnDataC(response.data.data.columns.headerColumns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        showToast({ type: 'error', message: error.toString() });
                    })
            }
            else if (props.SettlementTo === 'R') {
                let params = [];
                params = [
                    ...params,
                    {
                        'attr': 'customerToId',
                        'value': type === 8? '2' : type === 9? '3' : '' + type,
                        'isDistinct': true
                    },
                    {
                        'attr': 'contactId',
                        'value': '',
                        'isDistinct': true
                    },
                    { 'attr': 'isAllPV', 'value': 'false' },
                    { 'attr': 'receiptByCode', 'value': 'D', 'mathSign': '!=', 'isDistinct': true },
                    { 'attr': 'isApprovedAcc', 'value': 'true' }
                ];
                param = 'receiptVoucher/receiptVoucher';

                ApiService.FinAccPostByPage(param, 'SEACON', pageNumber, pageSize, params, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.receiptVouchers);

                            let temp = response.data.data.receiptVouchers
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            setColumnDataC(response.data.data.columns.headerColumns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        showToast({ type: 'error', message: error.toString() });
                    })
            }
            else if (props.SettlementTo === 'M') {
                setIsLoadingC(false);
                handleClick();
            }
        }
        else {
            showToast({ type: 'error', message: 'Error When Fetching Data!' });
        }
    };

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let title = JSON.parse(localStorage.getItem("title"));
        if (title === 'Bank') {
            let param = 'bank/bank';
            let params = [{ "attr": "isWithCOA", "value": "1" }];
            if (props.PaymentBy === 'BI') {
                params = [
                    ...params,
                    { 'attr': 'amountCrr', 'value': '1' }
                ]
            }
            else {
                params = [
                    ...params,
                    { 'attr': 'amountCrr', 'value': '0' }
                ]
            }
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        setDataN(response.data.data.bank);

                        let temp = response.data.data.bank
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (title === 'City') {
            let param = 'city/city';
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        setDataN(response.data.data.city);

                        let temp = response.data.data.city
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (title === 'Kendaraan') {
            let param = 'kendaraan/kendaraan';
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        setDataN(response.data.data.kendaraan);

                        let temp = response.data.data.kendaraan
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
    };

    function handleSetPPh23Percent(val) {
        props.setPPh23Percent(val);

        if (props.isInput === true) {
            let temp = props.PPh23AmountReal;
            temp = parseFloat(temp) - parseFloat(props.TempMinus);
            props.setTempAmount(0);
            props.setPPh23Amount(convertToNominal(temp));
            props.setPPh23AmountReal(temp);
            props.setIsInput(false);
            let newArray = props.DataPaymentDetail;

            if (props.isAllPPh === true) {
                newArray.forEach(element => {
                    element.isPPh23 = false;
                });
                props.setDataPaymentDetail(newArray);
            }

            props.setIsAllPPh(false);
            props.calculateTotalDebitCredit(newArray, props.Rate, props.PaymentBy, temp);
        }
    }

    function handleChangePPh23(val) {
        if (val !== null && val !== undefined && val !== '') {
            let value = 0;
            if (formatCurrency === 'USD') {
                value = convertMask(val);
            }
            else {
                value = convertMaskIDR(val);
            }

            props.setPPh23AmountReal(value);
            props.setPPh23Amount(convertToNominal(value));

            if (props.isAddValue === false) {
                if (props.selectedPaymentVoucher.sequence !== undefined) {
                    let newArr = props.DataPaymentDetail;
                    let tempAmount = 0;
                    let tempPaymentAmount = 0;
                    props.setTempIsPPh23(true);
                    newArr.forEach((el) => {
                        if (props.PaymentBy === 'CU' || props.PaymentBy === 'BU') {
                            tempAmount = parseFloat(el.amountUSD) + parseFloat(tempAmount);
                        }
                        else {
                            tempAmount = parseFloat(el.amountIDR) + parseFloat(tempAmount);
                        }
                    });
                    tempPaymentAmount = parseFloat(tempAmount) - parseFloat(value);
                    props.setTempAmount(tempPaymentAmount);

                    if (props.PaymentBy === 'CU' || props.PaymentBy === 'CI') {
                        props.setCashPayment(tempPaymentAmount);
                    }
                }
            }

            props.calculateTotalDebitCredit(props.DataPaymentDetail, props.Rate, props.PaymentBy, value);
        }
    }

    return (
        <div>
            <form>
                <Box>
                    <Box>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Payment Voucher No"
                                    textAlign='right'
                                    value={props.PVNo}
                                    disabled
                                    hidden={!props.isEdit}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Reference"
                                    value={props.Reference}
                                    disabled
                                    hidden={!props.isEdit}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <div style={{ marginTop: props.isEdit ? '16px' : '0px' }}>
                                    {props.selectSettlementTo(props.SettlementTo, handlePaymentToSelect, props.isEdit)}
                                </div>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Selection
                                    id='0'
                                    label={'Payment By'}
                                    fullWidth={true}
                                    placeholder={'Select Payment By'}
                                    value={props.PaymentBy}
                                    onChange={(e) => handlePaymentBy(e.target.value)}
                                    disabled={props.isEdit}
                                >
                                    {
                                        dataPaymentBy.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }} hidden={props.isBank}>
                                    <Input
                                        label='Bank'
                                        value={props.BankCode}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '50%'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                        }}
                                    />

                                    <Input
                                        value={props.BankIntName}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '50%'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.BankName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchBank('delete')}
                                                        disabled={props.isBank}
                                                        hidden={props.BankName && !props.isEdit ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => searchBank('search')}
                                                        disabled={props.isBank}
                                                        hidden={props.isEdit}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>

                                <div>
                                    {props.selectPrincipleBy}
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <div style={{ marginTop: '16px' }}>
                                    <Selection
                                        id='0'
                                        label={'Payment To'}
                                        fullWidth={true}
                                        placeholder={'Select Payment To'}
                                        value={props.CustomerTo}
                                        onChange={(e) => handlePaymentTo(e.target.value)}
                                        disabled={props.isEdit}
                                    >
                                        {
                                            dataPaymentTo.map((data, index) => (
                                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code} hidden={data.disabled}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Selection>
                                </div>

                                <div hidden={props.CustomerTo === 0}>
                                    <Stack spacing={'16px'} direction="row">
                                        <Input
                                            label='Customer'
                                            value={props.ContactCode}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '50%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />

                                        <Input
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            value={props.ContactName}
                                            type='text'
                                            label={''}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                            inputBaseProps={{
                                                sx: {
                                                    marginTop: '23px !important',
                                                },
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => searchContact('delete')}
                                                            disabled={props.isNot}
                                                            hidden={props.ContactName && !props.isEdit ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton
                                                            disabled={!props.isJobOwner === true && props.isNot === false ? false : true}
                                                            hidden={props.isEdit}
                                                            onClick={() => searchContact('search')}
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Stack>
                                </div>

                                <div hidden={props.CustomerTo !== 0}>
                                    <Input
                                        label={'Address'}
                                        multiline
                                        fullWidth
                                        value={props.Address}
                                        disabled={props.isYes && props.isEdit}
                                        onChange={(e) => props.setAddress(e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                        inputBaseProps={{
                                            rows: 8,
                                            sx: {
                                                marginTop: '3px !important',
                                                display: 'flex',
                                                padding: '8px',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                alignSelf: 'stretch',
                                                borderRadius: '8px',
                                                border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                                background: '#F2F2F2',
                                            },
                                        }}
                                        formLabelProps={{
                                            sx: {
                                                fontFamily: 'Nunito',
                                                fontSize: '16px',
                                                fontWeight: '700',
                                                color: '#083A50 !important',
                                                textAlign: 'left'
                                            }
                                        }}
                                    />
                                </div>

                                <div hidden={props.CustomerTo !== 10}>
                                    <Input
                                        label='No Polisi'
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.NoPolisi}
                                        type='text'
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchNoPolisi('delete')}
                                                        disabled={isSelectTrucking}
                                                        hidden={props.NoPolisi ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => searchNoPolisi('search')} disabled={isSelectTrucking}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled={props.isEdit}
                                    />
                                </div>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'16px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label='Rate'
                                            value={props.Rate}
                                            textAlign='right'
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />

                                        <Input
                                            label='Verify Acc'
                                            value={props.verifyAccOn}
                                            type={'date'}
                                            disabled
                                            hidden={!props.isEdit}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label='Date Rate'
                                            value={props.ExRateDate}
                                            type={'date'}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />

                                        <Input
                                            label='Print Date'
                                            value={props.PrintedOn}
                                            type={'date'}
                                            disabled
                                            hidden={!props.isEdit}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <div style={{ marginTop: '16px' }} disabled={props.isEdit}>
                                    <Selection
                                        id='0'
                                        label={'Include PPh 23'}
                                        fullWidth={true}
                                        placeholder={'Select Include PPh 23'}
                                        value={props.IsPPh23}
                                        onChange={(e) => handleInclude(e.target.value)}
                                        disabled={props.isEdit}
                                    >
                                        {
                                            dataInclude.map((data, index) => (
                                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Selection>

                                    <Grid
                                        container
                                        spacing={'16px'}
                                        hidden={props.isInclude}
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <Selection
                                                id='0'
                                                label={'Percent'}
                                                fullWidth={true}
                                                placeholder={'Select Percent'}
                                                value={props.PPh23Percent}
                                                onChange={(e) => handleSetPPh23Percent(e.target.value)}
                                                disabled={props.isInclude || props.isApprovedAcc}
                                                formControlProps={{
                                                    sx: {
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            >
                                                {
                                                    dataPercent.map((data, index) => (
                                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                                    ))
                                                }
                                            </Selection>

                                            <Input
                                                label='Amount'
                                                value={props.PPh23AmountReal}
                                                onChange={(e) => handleChangePPh23(e.target.value)}
                                                disabled={props.isInclude || props.isApprovedAcc}
                                                fullWidth
                                                textAlign='right'
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                }}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <Input
                                                label={'Remarks'}
                                                multiline
                                                fullWidth
                                                disabled={props.isInclude || props.isApprovedAcc}
                                                value={props.PPh23Remarks}
                                                onChange={(e) => props.setPPh23Remarks(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    rows: 5,
                                                    sx: {
                                                        padding: '8px',
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </form>

            {
                isModal && (
                    <SelectDataContact
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        tipe={props.CustomerTo}
                        SettlementTo={props.SettlementTo}
                        getData={getDataContact}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        setContactId={props.setContactId}
                        setContactCode={props.setContactCode}
                        setContactName={props.setContactName}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataNonContact
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}
                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        data={dataN}
                        columnData={columnDataN}
                        dataMap={dataMapN}
                        setBankId={props.setBankId}
                        setBankCode={props.setBankCode}
                        setBankIntName={props.setBankIntName}
                        setBankName={props.setBankName}
                        setCityId={props.setCityId}
                        setCityInt={props.setCityInt}
                        setCityName={props.setCityName}
                        setKendaraanId={props.setKendaraanId}
                        setNoPolisi={props.setNoPolisi}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                    />
                )
            }
        </div>
    )
}

export default PaymentVoucherHeader;