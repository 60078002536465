import React, { useState } from 'react';
import { Table } from 'react-bootstrap';

import BodyComponent from './BodyComponent';
import { Icon, Selection, Input } from '../ui';
import {
    // IconButton, InputAdornment, 
    Stack, Typography
} from '@mui/material';

const TableZ = (props) => {
    const [filterJson, setFilterJson] = useState({})
    const [filterArr, setFilterArr] = useState([])

    const filterTable = (key, val, type = 'Standard') => {
        let filter = filterJson
        let temp = {
            field: key,
            data: val?.trim()
        }
        let arr = []
        if (!filter[key]) {
            filter[key] = temp
            setFilterJson(filter)
        } else {
            filter[key].data = val
            setFilterJson(filter)
        }

        if (filter[key].data.length === 0) {
            delete filter[key]
            delete props.stateX[key]
            setFilterJson(filter)
        } else {
            props.setStateX({ ...props.stateX, [key]: val })
        }

        for (const key in filter) {
            arr.push(filter[key])
        }

        setFilterArr(arr)

        if (type === 'Immediately') {
            props.setNumPage(1)
            props.setRowsCount(50)
            props.setGoTo(1)
            let arr = []
            let arrFilter = []
            let filter = { ...props.stateX, [key]: val }
            for (const [key, value] of Object.entries(filter)) {
                let realCol = key;
                let realValue = value;

                if (realValue === 'true') {
                    realValue = 'Yes';
                }
                else if (realValue === 'false') {
                    realValue = 'No';
                }
                else if (realValue === 'ACT') {
                    realValue = 'Active';
                }
                else if (realValue === 'DEL') {
                    realValue = 'Deleted';
                }

                realCol = props.columnData.find(el => el.column === key);

                arr.push(realCol.text + ': ' + realValue)

                arrFilter = [
                    ...arrFilter,
                    {
                        field: key,
                        data: value?.trim()
                    }
                ]
            }
            props.setFilterBy(arr)
            props.setFilter(arrFilter)
            if (props.id !== undefined) {
                props.getData(1, props.rowsCount, arrFilter, props.id, props.sortArr)
            }
            else {
                props.getData(1, props.rowsCount, arrFilter, props.sortArr)
            }
        }
    }

    const handleDoubleClick = (el) => {
        props.CellDoubleClick(el)
    }

    const handleClick = (el) => {
        if (props?.static) return

        if (el) {
            props.setSelectedData(el);
        }
        if (props.handleGetData !== undefined) {
            props.handleGetData(el);
        }
    }

    function handleKeyDown(e) {
        if (props?.preventKeyCapture) return
        if (props?.static && e.keyCode !== 13) return

        const keycode = e.keyCode;
        const valid = [38, 40, 13]

        if (!props.isModal && valid.includes(keycode)) {
            let currIndex = 0
            if (e.keyCode === 38 && props.SelectedData.index > 1) {
                //UP ARROW
                currIndex = props.SelectedData.index
                currIndex -= 1
            } else if (e.keyCode === 40 && props.SelectedData.index < props.dataMap.length - 1) {
                //DOWN ARROW
                currIndex = props.SelectedData.index
                currIndex += 1
            } else if (e.keyCode === 13 && filterArr.length >= 0) {
                //PRESS ENTER
                //THEN DO FILTER
                if (filterArr.length > 0) {
                    FilterData();
                }
            }

            if (![13].includes(e.keyCode)) {
                const copyArr = [...props.dataMap]
                const changeSelectedData = (data) => {
                    return data.index === currIndex
                }
                const result = copyArr.filter(changeSelectedData)
                props.setSelectedData(...result);
                handleClick(...result)
            }
        }
    }

    const FilterData = () => {
        props.setNumPage(1)
        props.setRowsCount(50)
        props.setGoTo(1)
        let arr = []
        let arrFilter = []
        let filter = props.stateX
        for (const [key, value] of Object.entries(filter)) {
            let realCol = key;
            let realValue = value;

            if (realValue === 'true') {
                realValue = 'Yes';
            }
            else if (realValue === 'false') {
                realValue = 'No';
            }
            else if (realValue === 'ACT') {
                realValue = 'Active';
            }
            else if (realValue === 'DEL') {
                realValue = 'Deleted';
            }

            realCol = props.columnData.find(el => el.column === key);

            arr.push(realCol.text + ': ' + realValue)

            arrFilter = [
                ...arrFilter,
                {
                    field: key,
                    data: value?.trim()
                }
            ]
        }
        props.setFilterBy(arr)
        props.setFilter(arrFilter)
        if (props.id !== undefined) {
            props.getData(1, props.rowsCount, arrFilter, props.id, props.sortArr)
        }
        else {
            props.getData(1, props.rowsCount, arrFilter, props.sortArr)
        }
    }

    const SortData = (key) => {
        if (props.sortArr) {
            let temp = {
                attr: key,
                method: 'asc'
            }
            var arr = []
            var cekArr = props.sortArr
            cekArr = cekArr.filter(el => el.attr === key)

            if (cekArr.length > 0) {
                arr = props.sortArr
                arr.forEach(el => {
                    if (el.attr === key) {
                        if (el.method === 'asc') {
                            el.method = 'desc'
                        } else {
                            arr = arr.filter(el => el.attr !== key)
                        }
                    }
                });
            } else {
                arr = [...props.sortArr, temp]
            }
            props.setSortArr(arr)

            let arrFilter = []
            let filter = props.stateX
            for (const [key, value] of Object.entries(filter)) {
                arrFilter = [...arrFilter, {
                    field: key,
                    data: value?.trim()
                }]
            }
            props.getData(1, props.rowsCount, arrFilter, arr)
            props.setNumPage(1)
            props.setGoTo(1)
            // CekSortStatus(key)
        }
    }

    const CekSortStatus = (key) => {
        if (props.sortArr) {
            const arr = props.sortArr.filter(el => el.attr === key)
            if (arr.length > 0) {
                if (arr[0].method === 'asc') {
                    return (
                        <Stack direction={'column'} spacing={'0px'}>
                            <Icon
                                iconName={'arrow-up'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#0096D8'
                                    }
                                }}
                            />

                            <Icon
                                iconName={'arrow-down'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#D8D8D8'
                                    }
                                }}
                            />
                        </Stack>
                    )
                }
                else {
                    return (
                        <Stack direction={'column'} spacing={'0px'}>
                            <Icon
                                iconName={'arrow-up'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#D8D8D8'
                                    }
                                }}
                            />

                            <Icon
                                iconName={'arrow-down'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#0096D8'
                                    }
                                }}
                            />
                        </Stack>
                    )
                }
            }
            else {
                return (
                    <Stack direction={'column'} spacing={'0px'}>
                        <Icon
                            iconName={'arrow-up'}
                            svgIconProps={{
                                sx: {
                                    width: '8px',
                                    height: '8px',
                                    color: '#D8D8D8'
                                }
                            }}
                        />

                        <Icon
                            iconName={'arrow-down'}
                            svgIconProps={{
                                sx: {
                                    width: '8px',
                                    height: '8px',
                                    color: '#D8D8D8'
                                }
                            }}
                        />
                    </Stack>
                )
            }
        }
    }

    return (
        <Table hover={!props?.static} className='table table-sm' style={{ '--bs-table-bg': '#F2F2F2' }} onKeyDown={handleKeyDown}>
            <thead style={{ position: 'sticky', top: '-4px', 'color': '#F2F2F2', background: '#F2F2F2', zIndex: 1 }}>
                <tr>
                    <th></th>
                    {
                        props.columnData.map((el, index) => {
                            return (
                                <th
                                    key={index}
                                    hidden={el.hidden}
                                    style={{
                                        textAlign: el.textAlign,
                                        "whiteSpace": "nowrap",
                                        fontWeight: 'normal',
                                        maxWidth: 'fit-content',
                                        cursor: 'pointer',
                                        padding: '2px 8px',
                                        background: '#F2F2F2',
                                        fontSize: '12px !important'
                                    }}
                                >
                                    <label
                                        onClick={() => SortData(el.column)}
                                        style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            alignItems: 'center',
                                            fontSize: '12px !important',
                                            maxWidth: 'fit-content'
                                        }}
                                    >
                                        <Stack
                                            direction={'row'}
                                            spacing={'6px'}
                                            sx={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                maxWidth: 'fit-content'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Nunito',
                                                    fontSize: '11px !important',
                                                    fontWeight: '700',
                                                    lineHeight: '16px',
                                                    textAlign: 'left',
                                                    color: '#083A50',
                                                    maxWidth: 'fit-content',
                                                }}
                                            >
                                                {el.text}
                                            </Typography>

                                            {CekSortStatus(el.column)}
                                        </Stack>
                                    </label>
                                </th>
                            )
                        })
                    }
                </tr>
                <tr>
                    <td></td>
                    {
                        props.columnData.map((el, index) => {
                            if (el.text === 'Data Status') {
                                return (
                                    <td key={index} hidden={el.hidden} align={el.textAlign} style={{ background: '#F2F2F2', padding: '24px 8px !important' }}>
                                        <Selection
                                            value={props.stateX[el.column]}
                                            fullWidth={true}
                                            onChange={(e) => filterTable(el.column, e.target.value, 'Immediately')}
                                            options={
                                                [
                                                    {
                                                        value: 'ACT',
                                                        label: 'Active'
                                                    },
                                                    {
                                                        value: 'DEL',
                                                        label: 'Deleted'
                                                    }
                                                ]
                                            }
                                            formControlProps={{
                                                sx: {
                                                    width: '100% !important',
                                                }
                                            }}
                                            selectBaseProps={{
                                                sx: {
                                                    // padding: '8px 8px !important', 
                                                    border: '1px solid #0087C2',
                                                    borderRadius: '10px',
                                                    fontSize: '10px !important',
                                                    height: '27px',
                                                    backgroundColor: 'transparent',
                                                }
                                            }}
                                            menuItemProps={{
                                                sx: {
                                                    color: '#0087C2',
                                                    fontFamily: 'Nunito',
                                                    fontWeight: '700',
                                                    fontSize: '12px !important'
                                                }
                                            }}
                                            selectInnerStyle={{
                                                backgroundColor: 'transparent',
                                                // padding: '0px 32px 0px 0px !important',
                                            }}
                                        />
                                    </td>
                                )
                            } else {
                                if (el.dataType === 'boolean' || el.dataType === 'bit') {
                                    return (
                                        <td
                                            key={index}
                                            hidden={el.hidden}
                                            align={el.textAlign}
                                            style={{
                                                background: '#F2F2F2',
                                                padding: '24px 8px !important',
                                                maxWidth: '150px !important',
                                            }}
                                        >
                                            <Selection
                                                value={props.stateX[el.column]}
                                                fullWidth={true}
                                                onChange={(e) => filterTable(el.column, e.target.value, 'Immediately')}
                                                options={[
                                                    // {
                                                    //     value: 'ALL',
                                                    //     label: 'All'
                                                    // },
                                                    {
                                                        value: 'true',
                                                        label: 'Yes'
                                                    },
                                                    {
                                                        value: 'false',
                                                        label: 'No'
                                                    }
                                                ]}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100% !important',
                                                    }
                                                }}
                                                selectBaseProps={{
                                                    sx: {
                                                        // padding: '8px 8px !important', 
                                                        border: '1px solid #0087C2',
                                                        borderRadius: '10px',
                                                        fontSize: '10px !important',
                                                        height: '27px',
                                                        backgroundColor: 'transparent',
                                                    }
                                                }}
                                                menuItemProps={{
                                                    sx: {
                                                        color: '#0087C2',
                                                        fontFamily: 'Nunito',
                                                        fontWeight: '700',
                                                        fontSize: '12px !important'
                                                    }
                                                }}
                                                selectInnerStyle={{
                                                    backgroundColor: 'transparent',
                                                    // padding: '0px 32px 0px 0px !important',
                                                }}
                                            />
                                        </td>
                                    )
                                } else {
                                    return (
                                        <td key={index} hidden={el.hidden} align={el.textAlign} style={{ background: '#F2F2F2', padding: '24px 8px !important' }}>
                                            <Input
                                                className="form-control col-search-form"
                                                value={props.stateX[el.column]}
                                                onChange={(e) => { filterTable(el.column, e.target.value, el.dataType === 'datetime' ? 'Immediately' : 'Standard'); }}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100% !important',
                                                        minWidth: '65px !important',
                                                        maxWidth: '100% !important',
                                                    }
                                                }}
                                                type={el.dataType !== 'datetime' ? 'text' : 'date'}
                                                inputBaseProps={{
                                                    sx: {
                                                        width: '100% !important',
                                                        padding: '5px 10px !important',
                                                        border: '1px solid #0087C2',
                                                        fontSize: '10px !important',
                                                        borderRadius: '10px',
                                                        maxWidth: '100% !important',
                                                        minHeight: '27px !important',
                                                    },
                                                    // endAdornment: 
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton sx={{ padding: '0px' }} onClick={() => FilterData()}>
                                                    //             <Icon 
                                                    //                 iconName={'search'}
                                                    //                 svgIconProps={{
                                                    //                     fontSize: 'small',
                                                    //                     htmlColor: '#083A50',
                                                    //                     sx: { 
                                                    //                         height: '16px', 
                                                    //                         width: '16px' 
                                                    //                     }
                                                    //                 }}
                                                    //             />
                                                    //         </IconButton>
                                                    //     </InputAdornment>
                                                }}
                                            />
                                        </td>
                                    )
                                }
                            }
                        })
                    }
                </tr>
            </thead>

            <tbody>
                {
                    props.dataMap.length > 0 ?
                        props.dataMap.map((el, index) => {
                            let tempStyle = 'text-dark'
                            if (props.SelectedData?.id === el?.id) {
                                tempStyle = "bg-infoss text-white"
                            }
                            else if (el.rowStatus === 'DEL') {
                                tempStyle = "text-danger"
                            }
                            else if (el.printing > 0) {
                                tempStyle = "text-secondary"
                            }

                            return (
                                <BodyComponent
                                    key={index} tempStyle={tempStyle} setDataMap={props.setDataMap}
                                    index={index} el={el} localVar={props.localVar}
                                    columnData={props.columnData} setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                                    handleClick={handleClick} source={props.source}
                                    handleDoubleClick={handleDoubleClick}
                                />
                            )
                        })
                        :
                        <tr>
                            <td colSpan={20} className="text-center py-3 text-muted">
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontstyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal'
                                    }}
                                >
                                    Data Empty
                                </Typography>
                            </td>
                        </tr>
                }
            </tbody>
        </Table>
    )
}

export default TableZ