import { Stack, Box, Menu, Badge, Avatar, AppBar, Toolbar, Backdrop, MenuItem, Typography, IconButton } from '@mui/material';
import { HubConnectionState } from '@microsoft/signalr';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '../../ui';
import { LightTooltip } from '../Sidebar/SideNavbar';
import majuraIconWhite from '../../../Assets/Icon/majuraIconWhite.svg';
import LoadingSpinner from '../../Components/LoadingSpinner';
import ApiService, { branchName, roleName, username, signalRConnection } from '../../../services/ApiService';

export default function AppHeader(props) {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [jmlTodo, setJmlTodo] = useState(0)
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);

  const history = useNavigate();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  let countRefresh = 0;

  useEffect(() => {
    if (countRefresh === 0) {
      countRefresh++;
    }

    if (signalRConnection.state === HubConnectionState.Disconnected) {
      getConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countRefresh]);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    name = name.toUpperCase();
    let nama = name;
    let pjg = name.split(' ').length
    if (pjg > 1) {
      nama = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    } else {
      nama = `${name.split(' ')[0][0]}`
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: '32px',
        height: '32px'
        // bgcolor: '#45454d',
      },
      children: nama
    };
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logOut = () => {
    if (isLoggingOut) return;  // Prevent multiple clicks

    setIsLoggingOut(true);
    handleMenuClose();
    setIsLoadingBackDrop(true);

    if (props?.childRef?.current?.localSave) {
      props.childRef.current.localSave();
    }

    localStorage.setItem(username + "-lastUrl", JSON.stringify(window.location.pathname));

    ApiService.logOut().then((res) => {
      setTimeout(() => {
        localStorage.setItem(username + "-isLogOut", JSON.stringify(true));
        setIsLoadingBackDrop(false);
        history('/', { replace: true });
        setIsLoggingOut(false); // Reset state after logout
      }, 500);
    });
  };

  async function getConnection() {
    try {
      signalRConnection.on("ReceiveMessage", (res) => {
        if (res === 'ToDoNotif') {
          ApiService.refreshToken();
          ApiService.OperationPostByPage('todo/todo', 1, 100)
            .then((response) => {
              if (response.data.code === 200) {
                setJmlTodo(response.data.totalRowCount)
              }
            })
            .catch(function (error) {
              console.error('error saat fetch', error)
            })
        }
      });

      signalRConnection.onclose(e => { });

      if (signalRConnection.state === HubConnectionState.Disconnected) {
        await signalRConnection.start();
        await signalRConnection.invoke("Show", JSON.parse(localStorage.getItem("userCode")));
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleProfile = () => {
    history('/Majura/profile')
    handleMenuClose()
  }

  const handleTodo = () => {
    history("/Majura")
    window.location.reload()
  }

  const handleMenu = () => {
    history("/Majura/mainMenu")
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      // anchorOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'right',
      // }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        sx={{
          fontSize: '12px',
          color: '#0087C2',
          fontFamily: 'Nunito',
          fontWeight: '700',
          padding: '8px 32px 8px 8px',
        }}
        onClick={handleProfile}
      >
        Profile
      </MenuItem>
      <MenuItem
        sx={{
          fontSize: '12px',
          color: '#0087C2',
          fontFamily: 'Nunito',
          fontWeight: '700',
          padding: '8px 32px 8px 8px',
        }}
        onClick={logOut}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}

      <MenuItem >
        <IconButton
          size="large"
          aria-label="show Menu"
          color="inherit"
          onClick={handleMenu}
        >
          <Badge color="error">
            <Icon iconName={'book'} />
          </Badge>
        </IconButton>
        <p>Menu</p>
      </MenuItem>

      <MenuItem>
        <IconButton
          size="large"
          aria-label="show new notifications"
          color="inherit"
          onClick={handleTodo}
        >
          <Badge badgeContent={jmlTodo} color="error">
            <Icon iconName={'bell'} />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {/* <AccountCircle /> */}
          {/* <Avatar alt="Profile Pic" src={profile} /> */}
          <Avatar {...stringAvatar(username)} />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1, alignContent: 'center', alignItems: 'center' }} style={{ width: '100%', padding: '0px 8px', height: 'fit-content' }}>
        <AppBar position="static" sx={{ boxShadow: 0, backgroundColor: 'transparent' }}>
          <Toolbar sx={{ p: 0 }}>
            <Stack direction={'row'} spacing={'24px'} sx={{ alignItems: 'center' }}>
              <div style={{ width: '295px', alignSelf: 'center !important', justifyContent: 'center' }}>
                <Avatar
                  src={majuraIconWhite}
                  sx={{ width: 'fit-content', height: '40.25px', marginLeft: '19.725px' }}
                  variant='square'
                  alt='.'
                />
              </div>

              <span
                style={{
                  width: '5px',
                  height: '40px',
                  transform: 'rotate(-0.476deg)',
                  borderRadius: '32px',
                  opacity: '0.6',
                  background: 'var(--Disable-Grey-1, #EAEAEA)',
                }}
              />

              <Box style={{ width: '100%' }}>
                <Typography
                  variant="h6"
                  display={{ xs: 'none', sm: 'block' }}
                  fontSize={14}
                  fontWeight={600}
                  lineHeight={'normal'}
                  fontFamily={'Nunito'}
                  color={'#F0F0F0'}
                >
                  {branchName}
                </Typography>

                <Typography
                  variant="h6"
                  display={{ xs: 'none', sm: 'block' }}
                  fontSize={14}
                  fontWeight={600}
                  lineHeight={'normal'}
                  fontFamily={'Nunito'}
                  color={'#F0F0F0'}
                >
                  {username}, {roleName}
                </Typography>
              </Box>
            </Stack>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Stack direction={'row'} spacing={'16px'}>
                <LightTooltip title="Main Menu">
                  <IconButton
                    size="small"
                    aria-label="show 4 new mails"
                    color="inherit"
                    onClick={handleMenu}
                    sx={{ padding: '0px' }}
                  >
                    <Badge >
                      <Icon
                        iconName={'book'}
                        svgIconProps={{
                          sx: {
                            height: '20px',
                            width: '20px'
                          }
                        }}
                      />
                    </Badge>
                  </IconButton>
                </LightTooltip>

                <LightTooltip title="To Do">
                  <IconButton
                    size="small"
                    aria-label="show new notifications"
                    color="inherit"
                    onClick={handleTodo}
                    sx={{ padding: '0px' }}
                  >
                    <Badge
                      badgeContent={jmlTodo}
                      color="error"
                      sx={{
                        "& .MuiBadge-badge": {
                          fontSize: '10px',
                          padding: '4px'
                        }
                      }}
                    >
                      <Icon iconName={'bell'}
                        svgIconProps={{
                          sx: {
                            height: '20px',
                            width: '20px'
                          }
                        }}
                      />
                    </Badge>
                  </IconButton>
                </LightTooltip>

                <LightTooltip title="Profile">
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    sx={{ padding: '0px' }}
                  >
                    <Avatar sx={{ width: '32px', height: '32px' }} {...stringAvatar(username)} />
                  </IconButton>
                </LightTooltip>
              </Stack>
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <Icon iconName={'three-dot'} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        {renderMobileMenu}
        {renderMenu}
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingBackDrop}
      >
        <LoadingSpinner />
      </Backdrop>
    </>
  );
}