import React, { forwardRef, useEffect, useState } from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Stack, Backdrop, MenuItem, CardContent, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';
import ApiService, { companyName } from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Input, Selection, showToast } from '../../../../components/ui';

const RegisterSalesReport = forwardRef(() => {
  const history = useNavigate()

  const date = new Date()
  const dateString = date.toLocaleDateString("sv-se").split('T')[0]

  const [isLoading, setIsLoading] = useState(true)

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [listJobType, setListJobType] = useState([])

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Register Sales Report', link: '/Majura/RegisterSalesReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Register Sales Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    // TODO Change menu code
    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OBOCO')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getAdditionalData()
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Majura');
        });
    } else {
      history('/Majura');
    }
  };

  const getAdditionalData = async () => {
    setIsLoading(true)
    try {
      const [resJT] = await Promise.all([
        ApiService.DynamicPostByPage('regJobType/regJobType', 'PAGE', 1, 999, []),
      ])

      if (resJT.data.code === 200) {
        const data = resJT.data.data.jobType
        setListJobType(data)
      } else {
        errorToast('job type')
      }

    } catch (error) {
      errorToast('additional')
      console.log(error)
    }
    setIsLoading(false)
  }

  const handlePrint = (printType) => {
    const payload = formik.values

    if ((payload.isCustomPeriod && (!payload?.fromPeriod || !payload?.toPeriod)) || (!payload?.isCustomPeriod && !payload?.period)) {
      return showToast({ type: 'error', message: 'Please enter valid period.' })
    }

    setIsLoading(true);
    let dateStart = '';
    let dateEnd = '';
    let isCustom = '';

    if (payload.isCustomPeriod === true) {
      isCustom = '1';
      dateStart = payload.fromPeriod;
      dateEnd = payload.toPeriod;
    }
    else {
      isCustom = '0';
      let date = new Date(payload.period);
      dateStart = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("sv-se").split('T')[0];
      dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString("sv-se").split('T')[0];
    }

    let variable = 'PrintRegisterSales?isRecap=' + printType;
    let params = [
      {
        'attr': 'jobTypeId',
        'value': '' + payload.jobType
      },
      {
        "attr": "IsCustom",
        "value": '' + isCustom
      },
      {
        "attr": "StartDate",
        "value": '' + dateStart
      },
      {
        "attr": "EndDate",
        "value": '' + dateEnd
      }
    ];
    ApiService.ReportPrint("Reports/Finance/Print/Other/RegisterSalesReport/", variable, params)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/vnd.ms-excel";
          const file = new Blob([response.data], { type: rptFormat });
          const fileURL = URL.createObjectURL(file);

          setIsLoading(false);
          showToast({ type: 'success', message: 'Print Register Sales Report Success' });
          window.open(fileURL);
        }
        else {
          setIsLoading(false);
          showToast({ type: 'error', message: 'Can\'t Print Register Sales Report, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Register Sales Report, ' + error });
      });
  }

  const formik = useFormik({
    initialValues: {
      company: companyName,
      jobType: '0',
      isCustomPeriod: false,
      period: `${date.getFullYear()}-${date.getMonth() + 1}`,
      fromPeriod: dateString,
      toPeriod: dateString,
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {dataRegUserAccess.map((elm, index) => (
              <React.Fragment key={index}>
                {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint('true'), 'Rekap Excel')}
                {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint('false'), 'Excel')}
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Register Sales Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '24px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Input
                        disabled
                        fullWidth
                        label="Company"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        value={formik.values?.company || ''}
                        formControlProps={{ sx: { width: '100%' } }}
                      />

                      <Selection
                        id='0'
                        fullWidth={true}
                        label="Job Type"
                        value={formik.values.jobType}
                        placeholder={'Select Job Type'}
                        formControlProps={{ sx: { marginTop: '16px' } }}
                        onChange={(e) => formik.setFieldValue('jobType', e.target.value)}
                      >
                        <MenuItem sx={{ fontSize: '12px' }} value={'0'}>All</MenuItem>
                        {
                          listJobType.map((jt) => (
                            <MenuItem sx={{ fontSize: '12px' }} key={jt.jobTypeId} value={jt.jobTypeId}>{jt.name}</MenuItem>
                          ))
                        }
                      </Selection>

                      <div style={{ marginTop: '16px' }}>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                          }}
                        >
                          Period
                        </Typography>

                        <RadioGroup
                          row
                          name="variant"
                          aria-labelledby="variant-option"
                          value={formik.values.isCustomPeriod}
                          onChange={(e) => { formik.setFieldValue('isCustomPeriod', (e.target.value === 'true')) }}
                        >
                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={false}
                            key={1}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Monthly
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={true}
                            key={2}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Custom Period
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </div>

                      {
                        !formik.values.isCustomPeriod ?
                          <Input
                            fullWidth
                            type='month'
                            label="Period"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={formik.values?.period || ''}
                            formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                            onChange={(e) => formik.setFieldValue('period', e.target.value)}
                          />
                          :
                          <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '16px' }}>
                            <Input
                              fullWidth
                              type='date'
                              label="From"
                              variant="standard"
                              InputLabelProps={{ shrink: true }}
                              value={formik.values.fromPeriod}
                              formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                              onChange={(e) => formik.setFieldValue('fromPeriod', e.target.value)}
                            />

                            <Input
                              fullWidth
                              label="To"
                              type='date'
                              variant="standard"
                              InputLabelProps={{ shrink: true }}
                              value={formik.values.toPeriod}
                              formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                              onChange={(e) => formik.setFieldValue('toPeriod', e.target.value)}
                            />
                          </Stack>
                      }
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </>
  )
})

export default RegisterSalesReport