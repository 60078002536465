import { Grid, IconButton, MenuItem, InputAdornment, Stack } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ApiService, { branchId, companyId, countryId, userId, userCode, formatCurrency } from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';

import SelectDataAccount from '../Selects/SelectDataAccount';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../components/ui';
import { convertToNumeric } from '../../../../utils/masks.jsx';

const ModalInvoices = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const param = "account/account";
    const [data, setData] = useState([]);
    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [totalRows, setTotalRows] = useState(50);
    const [SelectedData, setSelectedData] = useState({});
    let countErr = 1;

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getData(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal('Account');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountId(0);
            props.setAccountCode('');
            props.setAccountName('');
            props.setCOACode('');
            props.setCOAName('');
            props.setDescription('');
            props.setIsContainer(false);
            props.setContainerType('All');
            props.setContainerFormat('All');
            props.setQuantity(0);
            props.setAmount(0);
            props.setIsOthers(false);
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setAccountId(0);
        props.setAccountCode('');
        props.setAccountName('');
        props.setCOACode('');
        props.setCOAName('');
        props.setDescription('');
        props.setContainerType('All');
        props.setQuantity('');
        props.setUnitCost('');
        props.setSign(true);
        props.setAmount('');
        props.setVatId(0);
        props.setCostToCost(false);
        props.setOriginalUSD('');
        props.setOriginalRate('');
        props.setIsContainer(false);

        props.setContainerTypeId(0);
        props.setContainerTypeCode('');
        props.setContainerTypeName('');
        props.setContainerFormat('All');
        props.setIsOthers(false);
    };

    const BankTypeData = [
        { id: 1, code: 0, name: 'USD' },
        { id: 2, code: 1, name: 'IDR' },
    ];

    const CostToCostData = [
        { id: 1, code: false, name: 'No' },
        { id: 2, code: true, name: 'Yes' },
    ];

    const signData = [
        { id: 1, code: true, name: '+' },
        { id: 2, code: false, name: '-' }
    ];

    const handleSave = () => {
        if (!props.AccountCode) {
            showToast({ type: 'error', message: 'Please Select Account!' });
        }
        else {
            if (props.Quantity <= 0) {
                showToast({ type: 'error', message: 'Please Input Quantity Unit!' });
            }
            else {
                if (!props.Amount) {
                    showToast({ type: 'error', message: 'Please Input Amount!' });
                }
                else {
                    let Sequence = 1;
                    let invId = 0;
                    let amountVat = 0;
                    let vatPercent = 0.00;

                    if (props.detailEdit) {
                        Sequence = props.selectedDetail.sequence;
                    }
                    else {
                        if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                    }

                    if (props.invoiceId !== undefined || props.invoiceId !== null) {
                        invId = props.invoiceId;

                        if (invId === undefined) {
                            invId = 0;
                        }
                    }
                    else {
                        invId = 0;
                    }

                    for (let i = 0; i < props.VATData.length; i++) {
                        if (props.VatId === props.VATData[i].id) {
                            vatPercent = props.VATData[i].value;
                            break;
                        }
                    }

                    amountVat = parseFloat(convertToNumeric(props.Amount)) * parseFloat(vatPercent) / parseFloat(100);

                    let data = {
                        "countryId": countryId,
                        "companyId": companyId,
                        "branchId": branchId,
                        "userId": userId,
                        "userCode": userCode,

                        "rowStatus": "ACT",
                        "sequence": Sequence,
                        "id": 0,
                        "invoiceId": invId,
                        "debetCredit": props.PaymentType,
                        "accountId": props.AccountId,
                        "accountCode": props.AccountCode,
                        "accountName": props.AccountName,
                        "coaCode": props.COACode,
                        "coaName": props.COAName,
                        "description": props.Description,
                        "type": '0',
                        "containerSize": props.ContainerType,
                        "formatContainer": props.ContainerFormat,
                        "containerTypeId": props.containerTypeId,
                        "containerTypeCode": props.containerTypeCode,
                        "containerTypeName": props.containerTypeName,
                        "isContainer": props.isContainer,
                        "codingQuantity": false,
                        "quantity": props.Quantity ? parseFloat(props.Quantity) : parseFloat(0),
                        "perQty": convertToNumeric(props.UnitCost),
                        "sign": props.Sign,
                        "amountCrr": props.AmountCrr,
                        "amount": convertToNumeric(props.Amount),
                        "percentVat": vatPercent,
                        "amountVat": parseFloat(amountVat),
                        "eplDetailId": 0,
                        "vatId": props.VatId,
                        "isCostToCost": props.CostToCost,
                        "originalUsd": convertToNumeric(props.OriginalUSD),
                        "originalRate": convertToNumeric(props.OriginalRate),

                        "idLama": 0,

                        "invoiceDetailStorages": [],
                    }

                    if (!props.detailEdit) {
                        let newArray = props.DataPayment;

                        if (props.Currency === 'All') {
                            newArray = [...newArray, data];
                            props.setDataPayment(newArray);
                        }
                        else if (props.Currency === 'USD' && data.amountCrr === 0) {
                            newArray = [...newArray, data];
                            props.setDataPayment(newArray);
                        }
                        else if (props.Currency === 'IDR' && data.amountCrr === 1) {
                            newArray = [...newArray, data];
                            props.setDataPayment(newArray);
                        }

                        let newArray2 = props.TempDataPayment;
                        newArray2 = [...newArray2, data];
                        props.setTempDataPayment(newArray2);

                        if (props.PaymentFrom === 'I') {
                            if (props.Customer !== 'Agent') {
                                props.setDataPaymentSC(newArray2);
                            }
                            else {
                                props.setDataPaymentA(newArray2);
                            }
                        }

                        props.calculateTotal(newArray);
                    }
                    else {
                        const newArr = props.DataPayment.slice();

                        if (props.Currency === 'All') {
                            newArr.forEach(el => {
                                if (el.sequence === data.sequence) {
                                    el.debetCredit = data.debetCredit;
                                    el.accountId = data.accountId;
                                    el.accountCode = data.accountCode;
                                    el.accountName = data.accountName;
                                    el.coaCode = data.coaCode;
                                    el.coaName = data.coaName;
                                    el.description = data.description;
                                    el.type = data.type;
                                    el.containerSize = data.containerSize;
                                    el.formatContainer = data.formatContainer;
                                    el.containerTypeId = data.containerTypeId;
                                    el.containerTypeCode = data.containerTypeCode;
                                    el.containerTypeName = data.containerTypeName;
                                    el.isContainer = data.isContainer;
                                    el.quantity = data.quantity;
                                    el.perQty = data.perQty;
                                    el.sign = data.sign;
                                    el.amountCrr = data.amountCrr;
                                    el.amount = data.amount;
                                    el.percentVat = data.percentVat;
                                    el.amountVat = data.amountVat;
                                    el.vatId = data.vatId;
                                    el.isCostToCost = data.isCostToCost;
                                    el.originalUsd = data.originalUsd;
                                    el.originalRate = data.originalRate;
                                }
                            })
                            props.setDataPayment(newArr);
                        }
                        else if (props.Currency === 'USD' && data.amountCrr === 0) {
                            newArr.forEach(el => {
                                if (el.sequence === data.sequence) {
                                    el.debetCredit = data.debetCredit;
                                    el.accountId = data.accountId;
                                    el.accountCode = data.accountCode;
                                    el.accountName = data.accountName;
                                    el.coaCode = data.coaCode;
                                    el.coaName = data.coaName;
                                    el.description = data.description;
                                    el.type = data.type;
                                    el.containerSize = data.containerSize;
                                    el.formatContainer = data.formatContainer;
                                    el.containerTypeId = data.containerTypeId;
                                    el.containerTypeCode = data.containerTypeCode;
                                    el.containerTypeName = data.containerTypeName;
                                    el.isContainer = data.isContainer;
                                    el.quantity = data.quantity;
                                    el.perQty = data.perQty;
                                    el.sign = data.sign;
                                    el.amountCrr = data.amountCrr;
                                    el.amount = data.amount;
                                    el.percentVat = data.percentVat;
                                    el.amountVat = data.amountVat;
                                    el.vatId = data.vatId;
                                    el.percentVat = data.percentVat;
                                    el.amountVat = data.amountVat;
                                    el.isCostToCost = data.isCostToCost;
                                    el.originalUsd = data.originalUsd;
                                    el.originalRate = data.originalRate;
                                }
                            })
                            props.setDataPayment(newArr);
                        }
                        else if (props.Currency === 'IDR' && data.amountCrr === 1) {
                            newArr.forEach(el => {
                                if (el.sequence === data.sequence) {
                                    el.debetCredit = data.debetCredit;
                                    el.accountId = data.accountId;
                                    el.accountCode = data.accountCode;
                                    el.accountName = data.accountName;
                                    el.coaCode = data.coaCode;
                                    el.coaName = data.coaName;
                                    el.description = data.description;
                                    el.type = data.type;
                                    el.containerSize = data.containerSize;
                                    el.formatContainer = data.formatContainer;
                                    el.containerTypeId = data.containerTypeId;
                                    el.containerTypeCode = data.containerTypeCode;
                                    el.containerTypeName = data.containerTypeName;
                                    el.isContainer = data.isContainer;
                                    el.quantity = data.quantity;
                                    el.perQty = data.perQty;
                                    el.sign = data.sign;
                                    el.amountCrr = data.amountCrr;
                                    el.amount = data.amount;
                                    el.percentVat = data.percentVat;
                                    el.amountVat = data.amountVat;
                                    el.vatId = data.vatId;
                                    el.percentVat = data.percentVat;
                                    el.amountVat = data.amountVat;
                                    el.isCostToCost = data.isCostToCost;
                                    el.originalUsd = data.originalUsd;
                                    el.originalRate = data.originalRate;
                                }
                            })
                            props.setDataPayment(newArr);
                        }

                        const newArr2 = props.TempDataPayment.slice();
                        newArr2.forEach(el => {
                            if (el.sequence === data.sequence) {
                                el.debetCredit = data.debetCredit;
                                el.accountId = data.accountId;
                                el.accountCode = data.accountCode;
                                el.accountName = data.accountName;
                                el.coaCode = data.coaCode;
                                el.coaName = data.coaName;
                                el.description = data.description;
                                el.type = data.type;
                                el.containerSize = data.containerSize;
                                el.formatContainer = data.formatContainer;
                                el.containerTypeId = data.containerTypeId;
                                el.containerTypeCode = data.containerTypeCode;
                                el.containerTypeName = data.containerTypeName;
                                el.isContainer = data.isContainer;
                                el.quantity = data.quantity;
                                el.perQty = data.perQty;
                                el.sign = data.sign;
                                el.amountCrr = data.amountCrr;
                                el.amount = data.amount;
                                el.percentVat = data.percentVat;
                                el.amountVat = data.amountVat;
                                el.vatId = data.vatId;
                                el.percentVat = data.percentVat;
                                el.amountVat = data.amountVat;
                                el.isCostToCost = data.isCostToCost;
                                el.originalUsd = data.originalUsd;
                                el.originalRate = data.originalRate;
                            }
                        })
                        props.setTempDataPayment(newArr2);

                        if (props.PaymentFrom === 'I') {
                            if (props.Customer !== 'Agent') {
                                props.setDataPaymentSC(newArr2);
                            }
                            else {
                                props.setDataPaymentA(newArr2);
                            }
                        }

                        props.calculateTotal(newArr);
                    }

                    emptyState();
                    handleClose();
                    props.setDetailEdit(false);
                    props.setSelectedDetail({});
                }
            }
        }
    }

    function handleOriginalUSD(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setOriginalUSD(value);
        }
    }

    function handleOriginalRate(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setOriginalRate(value);
        }
    }

    function handleQuantityUnit(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setQuantity(value);
            props.setAmount(parseFloat((convertToNumeric(value) * convertToNumeric(props.UnitCost)).toFixed(5)));
        }
    }

    function handleUnitCost(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setUnitCost(value);
            props.setAmount(parseFloat((convertToNumeric(value) * convertToNumeric(props.Quantity)).toFixed(5)));
        }
    }

    const getData = (pageNumber, pageSize, filters) => {
        setIsLoading(true);
        let params = [];
        if (props.portType === 'SEA') {
            params = [...params, { 'attr': 'Type', 'value': 'SEA' }];
        }
        else if (props.portType === 'AIR') {
            params = [...params, { 'attr': 'Type', 'value': 'AIR' }];
        }
        ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.account);

                    let temp = response.data.data.account
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false);
                countErr++
                if (countErr < 3) {
                    getData(1, 50);
                }
                else {
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                    setIsModal(false);
                }
            })
    };

    function handleContainerSize(value) {
        props.setContainerFormat(value);
        if (props.isContainer) {
            props.containerSizeData.forEach(element => {
                if (element.formatContainer === value) {
                    props.setContainerType(element.containerSize);
                    props.setContainerTypeId(element.containerTypeId);
                    props.setContainerTypeCode(element.containerTypeCode);
                    props.setContainerTypeName(element.containerTypeName);
                    handleQuantityUnit(element.totalContainer);
                }
            });
        }
        else {
            props.setContainerType('All');
            props.setContainerTypeId(0);
            props.setContainerTypeCode('');
            props.setContainerTypeName('');
            handleQuantityUnit(0);
        }
    }

    const mainContainer = (
        <div style={{ padding: '12px 8px 0px' }}>
            <Input
                label={'Invoice To'}
                value={props.Customer}
                disabled
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
                fullWidth
            />

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label={props.Customer + ' Id'}
                        value={props.CustomerId}
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />

                    <div style={{ marginTop: '16px' }}>
                        {
                            props.isViewOnly ?
                                <Selection
                                    id='0'
                                    label={'Container Size'}
                                    fullWidth={true}
                                    placeholder={'Select Container Size'}
                                    value={props.ContainerFormat}
                                    onChange={(e) => handleContainerSize(e.target.value)}
                                    disabled
                                >
                                    <MenuItem sx={{ fontSize: '12px' }} key={0} value={'All'}>All</MenuItem>
                                    {
                                        props.isContainer ?
                                            props.containerSizeData.map((data, index) => (
                                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.formatContainer}>{data.formatContainer}</MenuItem>
                                            ))
                                            :
                                            <span></span>
                                    }
                                </Selection>
                                :
                                <Selection
                                    id='0'
                                    label={'Container Size'}
                                    fullWidth={true}
                                    placeholder={'Select Container Size'}
                                    value={props.ContainerFormat}
                                    onChange={(e) => handleContainerSize(e.target.value)}
                                    disabled={!props.isContainer}
                                >
                                    <MenuItem sx={{ fontSize: '12px' }} key={0} value={'All'}>All</MenuItem>
                                    {
                                        props.isContainer ?
                                            props.containerSizeData.map((data, index) => (
                                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.formatContainer}>{data.formatContainer}</MenuItem>
                                            ))
                                            :
                                            <span></span>
                                    }
                                </Selection>
                        }
                    </div>
                </Grid>

                <Grid
                    item
                    xl={8}
                    lg={8}
                    sm={8}
                    xs={12}
                >
                    <Input
                        label={props.Customer + ' Name'}
                        value={props.CustomerName}
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />

                    <Stack direction={'row'} spacing={'16px'}>
                        <Input
                            label={'Account Code'}
                            value={props.AccountCode}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '50%',
                                    marginTop: '16px !important'
                                }
                            }}
                        />

                        <Input
                            label={'Account Name'}
                            value={props.AccountName}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchAccount('delete')}
                                            hidden={props.AccountName && !props.isViewOnly ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => searchAccount('search')}
                                            hidden={props.isViewOnly}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>

            <Input
                label={'Description'}
                value={props.Description}
                onChange={(e) => props.setDescription(e.target.value)}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                disabled={props.isViewOnly}
            />

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Selection
                        id='0'
                        label={'Currency'}
                        fullWidth={true}
                        placeholder={'Select Currency'}
                        value={props.AmountCrr}
                        onChange={(e) => props.setAmountCrr(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px'
                            }
                        }}
                        disabled={props.isViewOnly}
                    >
                        {
                            BankTypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>

                    <Input
                        label={'Quantity Unit'}
                        textAlign='right'
                        fullWidth
                        value={props.Quantity}
                        disabled={props.isViewOnly}
                        onChange={(e) => handleQuantityUnit(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                ></Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Selection
                        id='0'
                        label={'Cost To Cost'}
                        fullWidth={true}
                        placeholder={'Select Cost To Cost'}
                        value={props.CostToCost}
                        onChange={(e) => props.setCostToCost(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px'
                            }
                        }}
                        disabled={!props.Type}
                    >
                        {
                            CostToCostData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label={'Per Unit Cost'}
                        textAlign='right'
                        fullWidth
                        value={props.UnitCost}
                        disabled={props.isViewOnly}
                        onChange={(e) => handleUnitCost(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Selection
                        id='0'
                        label={'Sign'}
                        fullWidth={true}
                        placeholder={'Select Sign'}
                        value={props.Sign}
                        hidden={props.Customer === 'Agent' ? false : true}
                        disabled={props.isViewOnly}
                        onChange={(e) => props.setSign(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px'
                            }
                        }}
                    >
                        {
                            signData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label={'Original Rate'}
                        textAlign='right'
                        fullWidth
                        value={props.OriginalRate}
                        disabled={props.isViewOnly}
                        onChange={(e) => handleOriginalRate(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label={'Amount'}
                        textAlign='right'
                        fullWidth
                        value={props.Amount}
                        disabled={props.isViewOnly}
                        onChange={(e) => props.setAmount(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    {props.selectVATData(props.VatId, props.setVatId, '', true)}
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label={'Original USD'}
                        textAlign='right'
                        fullWidth
                        value={props.OriginalUSD}
                        disabled={props.isViewOnly}
                        onChange={(e) => handleOriginalUSD(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )

    const buttonComponent = (
        <div style={{ padding: '6px 24px 12px' }}>
            {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, props.isViewOnly)}
        </div>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
                children={mainContainer}
                actions={buttonComponent}
            />

            {
                isModal && (
                    <SelectDataAccount
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getData}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        data={data}
                        setData={setData}
                        dataMap={dataMap}
                        setDataMap={setDataMap}
                        columnData={columnData}
                        setColumnData={setColumnData}
                        SelectedData={SelectedData}
                        setSelectedData={setSelectedData}
                        totalRows={totalRows}
                        setTotalRows={setTotalRows}

                        setAccountId={props.setAccountId}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                        setCOACode={props.setCOACode}
                        setCOAName={props.setCOAName}
                        setIsContainer={props.setIsContainer}
                        setDescription={props.setDescription}
                        setContainerType={props.setContainerType}
                        detailEdit={props.detailEdit}
                        setQuantity={props.setQuantity}
                        setContainerTypeId={props.setContainerTypeId}
                        setContainerTypeCode={props.setContainerTypeCode}
                        setContainerTypeName={props.setContainerTypeName}
                        setContainerFormat={props.setContainerFormat}
                        containerSizeData={props.containerSizeData}
                        handleQuantityUnit={handleQuantityUnit}
                        setIsOthers={props.setIsOthers}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}
                    />
                )
            }
        </>
    )
}

export default ModalInvoices;