import {
    Backdrop, Box, Grid, Table,
    TableBody, TableCell, TableContainer,
    TableHead, TableRow, MenuItem, Button,
    Card, CardContent, TableFooter, Typography
} from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import ModalAccountGL from './Modals/ModalAccountGL';
import ApiService from '../../../../services/ApiService';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';
import ButtonGeneralLedger from './Buttons/ButtonGeneralLedger';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';

const GeneralLedger = forwardRef((props, ref) => {
    const [period, setPeriod] = useState(new Date());
    const [period2, setPeriod2] = useState(new Date());
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [disablePeriod, setDisablePeriod] = useState(true)
    const [single, setSingle] = useState(0)
    const [allAccount, setAllAccount] = useState(0)
    const labelPeriod = "Period From";
    const labelPeriod2 = "Period To";
    const [disableButton, setDisableButton] = useState(true)
    const [dataAccount, setDataAccount] = useState([])
    const [dataCoa, setDataCoa] = useState([])
    const [isModal, setIsModal] = useState(false)
    const paramO = "regChartOfAccount/regChartOfAccount";

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const periodList = [
        { id: 1, code: 0, name: 'Single Period' },
        { id: 2, code: 1, name: 'Range Period' }
    ];

    const accountCodeList = [
        { id: 1, code: 0, name: 'All Account' },
        { id: 2, code: 1, name: 'Custom Per Account' }
    ]

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'General Ledger Report', link: '/Majura/GeneralLedgerReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'General Ledger - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataCOA()
        setDisablePeriod(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDefaultMonth = () => {
        const date = new Date();
        const year = date.getFullYear()
        let month = date.getMonth() + 1

        if (month < 10) {
            month = `0${month}`
        }

        setPeriod(`${year}-${month}`);
        setPeriod2(`${year}-${month}`);
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationGL() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport("pdf");
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport("xls")
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (allAccount === 1 && dataAccount.length <= 0) {
            check = 1;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Account!' })
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print PDF')
            setTitleModalConfirmation('General Ledger Report');
            handleClickConfirmation();
        }
    };

    const PrintExcel = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('General Ledger Report');
            handleClickConfirmation();
        }
    };

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
        }
    };

    const PrintReport = (code) => {
        const bln = new Date(period).getMonth();
        let bulan = bln + 1;
        const tahun = new Date(period).getFullYear();
        bulan = bulan.toString();
        if (bulan.length === 1) {
            bulan = ['0', bulan].join('');
        }
        const begin = [tahun, bulan, '01'].join('-')

        const bln2 = new Date(period2).getMonth();
        let bulan2 = bln2 + 1;
        const tahun2 = new Date(period2).getFullYear();
        var lastDay = new Date(tahun2, bulan2, 0);
        bulan2 = bulan2.toString();

        if (bulan2.length === 1) {
            bulan2 = ['0', bulan2].join('');
        }
        const end = [tahun2, bulan2, lastDay.getDate()].join('-')

        var accountCodeList = ""

        if (dataAccount.length > 0) {
            dataAccount.forEach(el => {
                accountCodeList = [accountCodeList, el.accountCode].join(',');
            });
        }

        accountCodeList = accountCodeList.substring(1);

        setIsLoadingPrint(true);
        let params = [
            {
                "attr": "startDate",
                "value": '' + begin
            },
            {
                "attr": "endDate",
                "value": '' + end
            },
            {
                "attr": "accountCodeList",
                "value": '' + accountCodeList
            }
        ]

        let variable = "PrintGL?typeReport=" + code;
        ApiService.ReportPrint("Reports/Finance/Print/FinanceReport/generalLedgerReport/", variable, params)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/pdf";
                    if (code === "xls") {
                        rptFormat = "application/vnd.ms-excel"
                    }
                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingPrint(false);
                    showToast({ type: 'success', message: 'Print General Ledger Report Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingPrint(false);
                    showToast({ type: 'error', message: 'Can\'t Print General Ledger Report, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingPrint(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print General Ledger Report, ' + error });
            });
    };

    const handlePeriod = (value) => {
        setSingle(value)
        if (value === 0) {
            setDisablePeriod(true)
            setPeriod2(period)
        }
        else {
            setDisablePeriod(false)
        }
    }

    const handleAccount = (value) => {
        setAllAccount(value)
        if (value === 0) {
            setDisableButton(true)
            Clear()
        }
        else {
            setDisableButton(false)
        }
    }

    const Clear = () => {
        setDataAccount([])
        setDataCoa([])
        getDataCOA()
    }

    const getDataCOA = () => {
        setIsLoadingPrint(true);
        ApiService.COAPostBySearch(paramO, 1, 1250, [], [], '6', '=')
            .then((response) => {
                if (response.data.code === 200) {
                    setDataCoa(response.data.data.chartOfAccount);
                    getDefaultMonth();
                }
                else {
                    ApiService.logOut();
                }
                setIsLoadingPrint(false);
            })
            .catch(function (error) {
                setIsLoadingPrint(false);
                console.error('error saat fetch', error)
            })
    }

    const ButtonComponents = () => {
        return (
            <ButtonGeneralLedger
                PrintData={PrintData}
                PrintExcel={PrintExcel}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'General Ledger Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            value={single}
                                            label="Period"
                                            fullWidth={true}
                                            placeholder={'Select Period'}
                                            onChange={(e) => handlePeriod(e.target.value)}
                                        >
                                            {
                                                periodList.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <div className='mt-3'>
                                            <Grid
                                                container
                                                spacing={'16px'}
                                            >
                                                <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Input
                                                        label={labelPeriod}
                                                        name="period"
                                                        value={period}
                                                        onChange={(e) => { setPeriod(e.target.value); setPeriod2(e.target.value) }}
                                                        type={'month'}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                            }
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Input
                                                        label={labelPeriod2}
                                                        name="period"
                                                        value={period2}
                                                        onChange={(e) => { setPeriod2(e.target.value) }}
                                                        type={'month'}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                            }
                                                        }}
                                                        disabled={disablePeriod}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            fullWidth={true}
                                            value={allAccount}
                                            label="By Account Code"
                                            placeholder={'Select By Account Code'}
                                            onChange={(e) => handleAccount(e.target.value)}
                                        >
                                            {
                                                accountCodeList.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <div className="row" spacing={'12px'} style={{ marginTop: '24px' }}>
                                            <div className='col-12' spacing={'12px'}>
                                                <Grid
                                                    item
                                                    container
                                                    direction="row"
                                                    spacing={'12px'}
                                                    display={'flex'}
                                                    justifyContent={'flex-end'}
                                                    sx={{ 'maxWidth': '100vw', margin: '0px', marginLeft: '-12px' }}
                                                >
                                                    <Button
                                                        size='small'
                                                        variant="outlined"
                                                        className='d-flex align-items-center'
                                                        sx={{
                                                            padding: '3px 6px',
                                                            gap: '4px',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            height: '18px !important',
                                                            minHeight: '28px !important',
                                                            borderRadius: '8px',
                                                            '&:hover': {
                                                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                                            }
                                                        }}
                                                        onClick={handleClick}
                                                        disabled={disableButton}
                                                    >
                                                        <Icon
                                                            svgIconProps={{
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px',
                                                                }
                                                            }}
                                                            iconName={'search'}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Nunito',
                                                                fontSize: '12px',
                                                                fontWeight: '700',
                                                                lineHeight: '20px',
                                                                letterSpacing: '0em',
                                                                textAlign: 'center',
                                                                color: 'inherit',
                                                            }}
                                                        >
                                                            Search
                                                        </Typography>
                                                    </Button>

                                                    <Button
                                                        size='small'
                                                        variant="outlined"
                                                        className='d-flex align-items-center'
                                                        sx={{
                                                            padding: '3px 6px',
                                                            gap: '4px',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            marginInlineStart: '12px',
                                                            height: '18px !important',
                                                            minHeight: '28px !important',
                                                            borderRadius: '8px',
                                                            '&:hover': {
                                                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                                            }
                                                        }}
                                                        onClick={Clear}
                                                        disabled={disableButton}
                                                    >
                                                        <Icon
                                                            svgIconProps={{
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px',
                                                                }
                                                            }}
                                                            iconName={'convert'}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Nunito',
                                                                fontSize: '12px',
                                                                fontWeight: '700',
                                                                lineHeight: '20px',
                                                                letterSpacing: '0em',
                                                                textAlign: 'center',
                                                                color: 'inherit',
                                                            }}
                                                        >
                                                            Clear
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            </div>
                                        </div>

                                        <TableContainer sx={{ maxHeight: 419, background: '#FFF', borderRadius: '12px', marginTop: '24px' }}>
                                            <Table stickyHeader size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell sx={{ textAlign: 'center', }}>Account Code</StyledTableCell>
                                                        <StyledTableCell sx={{ textAlign: 'center', }}>Account Title</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        dataAccount.length > 0 && dataAccount[0].sequence !== 0 ?
                                                            dataAccount.map((el) => {
                                                                return (
                                                                    <TableRow
                                                                        key={el.sequence}
                                                                    >
                                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.accountCode}</StyledTableBodyCell>
                                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.accountTitle}</StyledTableBodyCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                            :
                                                            <TableRow>
                                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                                    <Typography
                                                                        sx={{
                                                                            color: 'var(--Primary-One, #083A50)',
                                                                            fontFamily: 'Nunito',
                                                                            fontSize: '12px',
                                                                            fontstyle: 'normal',
                                                                            fontWeight: '500',
                                                                            lineHeight: 'normal'
                                                                        }}
                                                                    >
                                                                        Data Empty
                                                                    </Typography>
                                                                </StyledTableBodyCell>
                                                            </TableRow>
                                                    }
                                                </TableBody>
                                                <TableFooter sx={{ left: 0, bottom: 0, zIndex: 2, position: 'sticky' }} />
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModal && (
                    <ModalAccountGL
                        dataCoa={dataCoa}
                        isModal={isModal}
                        handleClick={handleClick}
                        setDataAccount={setDataAccount}
                        setDataCoa={setDataCoa}
                        dataAccount={dataAccount}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationGL}
                    />
                )
            }
        </Box>
    )
}
)
export default GeneralLedger