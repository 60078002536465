import { Box, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, tableCellClasses, Button, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { Icon, PopUp } from '../ui';

const ModalUsageData = React.memo(({ titleModal, isModal, handleClick, isDP, isDesc, description, usageDataList }) => {
    const [selectedData, setSelectedData] = useState({});
    const selectedStyle = { backgroundColor: '#DCDCDC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const showDocuments = (data) => {
        if (data.usedOn === 'Official Receipt') {
            window.open('/Majura/OfficialReceipt/' + data.id, '_blank');
        }
        else if (data.usedOn === 'Payment Voucher') {
            window.open('/Majura/PaymentVoucher/' + data.id, '_blank');
        }
        else if (data.usedOn === 'Receipt Voucher') {
            window.open('/Majura/ReceiptVoucher/' + data.id, '_blank');
        }
        else if (data.usedOn === 'Temporary Payment') {
            window.open('/Majura/TemporaryPayment/' + data.jobTypeId + '/' + data.id, '_blank');
        }
        else if (data.usedOn === 'Temporary Receipt') {
            if (data.isDP) {
                window.open('/Majura/DownPayment/' + data.id, '_blank');
            }
            else {
                window.open('/Majura/TemporaryReceipt/' + data.jobTypeId + '/' + data.id, '_blank');
            }
        }
    }

    const mainContainer = (
        <>
            <Box display="flex">
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '3px !important',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    {isDesc ? description : 'This Data Is Used In This Following Transaction:'}
                </Typography>
            </Box>

            <Box sx={{ marginTop: '16px' }}>
                <TableContainer sx={{ maxHeight: 230, borderRadius: '12px', background: '#FFF' }}>
                    <Table sx={{ minWidth: 450 }} stickyHeader>
                        <TableHead
                            sx={{
                                '& th:first-child': {
                                    borderRadius: '12px 0 0'
                                },
                                '& th:last-child': {
                                    borderRadius: '0 12px 0 0'
                                }
                            }}
                        >
                            <TableRow>
                                <StyledTableCell sx={{ userSelect: 'none', }}>
                                    {isDesc ? 'Source' : 'Used On'}
                                </StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center', userSelect: 'none', }}>Transaction Number</StyledTableCell>
                                {isDesc && (<StyledTableCell sx={{ textAlign: 'center', userSelect: 'none', }}>Action</StyledTableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                usageDataList.length > 0 ?
                                    usageDataList.map((el) => {
                                        return (
                                            <TableRow
                                                key={el.sequence}
                                                hover={true}
                                                onClick={() => setSelectedData(el)}
                                                onDoubleClick={() => showDocuments(el)}
                                                sx={
                                                    selectedData.sequence === el.sequence ?
                                                        selectedStyle
                                                        :
                                                        { backgroundColor: 'transparent' }
                                                }
                                            >
                                                <StyledTableBodyCell sx={{ userSelect: 'none', }}>{el.usedOn}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center', userSelect: 'none', }}>{el.transactionNumber}</StyledTableBodyCell>
                                                {
                                                    isDesc ?
                                                        <StyledTableBodyCell sx={{ textAlign: 'center' }}>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                disableFocusRipple={true}
                                                                disableRipple={true}
                                                                onClick={() => { showDocuments(el) }}
                                                                sx={{
                                                                    alignItems: 'center',
                                                                    padding: '0px !important',
                                                                    '&:hover': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                }}
                                                            >
                                                                <Stack direction={'row'} spacing={'3px'} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Typography
                                                                        sx={{
                                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                                            fontFamily: 'Nunito',
                                                                            fontSize: '14px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '700',
                                                                            lineHeight: '20px',
                                                                            letterSpacing: '-0.36px',
                                                                            'text-decoration-line': 'underline',
                                                                        }}
                                                                    >
                                                                        Detail
                                                                    </Typography>

                                                                    <Icon
                                                                        iconName={'arrow-right'}
                                                                        svgIconProps={{
                                                                            htmlColor: '#083A50',
                                                                            sx: {
                                                                                width: '12px',
                                                                                height: '12px',
                                                                            }
                                                                        }}
                                                                    />
                                                                </Stack>
                                                            </IconButton>
                                                        </StyledTableBodyCell>
                                                        :
                                                        <></>
                                                }
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px',
                                                    fontstyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal'
                                                }}
                                            >
                                                Data Empty
                                            </Typography>
                                        </StyledTableBodyCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={isDesc ? 'documents' : 'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#083A50',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={isDesc ? titleModal : 'Transaction Related To This ' + titleModal}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '24px 6px 16px' }}>
                    {mainContainer}
                </div>

                <div style={{ padding: '24px 6px 0px' }}>
                    <Box display="flex" alignItems="center">
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        background: 'var(--Blue-Button-Primary-1, #0087C2)',
                                        '&:hover': {
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={handleClick}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#E4E8EC',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }}
                                        iconName={'close'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#E4E8EC',
                                        }}
                                    >
                                        Close
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
})

export default React.memo(ModalUsageData);