import { Chip, Box, Stack, Button, Typography, Grid, IconButton } from '@mui/material';
import { Icon } from '../ui';
import React from 'react';

const handleDeleteChip = (
    chipToDelete, setFilterBy, filterBy,
    setFilter, setNumPage, setRowsCount,
    getData, stateX, setStateX, setGoTo,
    columnData, rowsCount, sortArr
) => {
    setFilterBy((chips) => chips.filter((chip) => chip !== chipToDelete));
    var dataFilter = filterBy.filter((data) => data !== chipToDelete)

    var dataArr = []
    let dataState = {}
    if (dataFilter.length > 0) {
        dataFilter.forEach(el => {
            let value = el.split(': ')
            let column = columnData.find(data => data.text === value[0]);
            let realValue = stateX[column.column];
            dataArr = [...dataArr, { field: column.column, data: realValue }];
            dataState[column.column] = realValue;
        });
    }

    setStateX(dataState)
    setFilter(dataArr)
    setNumPage(1)
    setRowsCount(rowsCount)
    setGoTo(1)
    getData(1, rowsCount, dataArr, sortArr)
};

class ButtonComponent {
    RefreshButton(sortArr, rowsCount, columnData, setGoTo, handleReset, setFilterBy, filterBy, setFilter, setNumPage, setRowsCount, getData, stateX, setStateX) {
        return (
            <>
                {
                    filterBy.length > 0 ?
                        <Box
                            alignItems="center"
                            justifyContent="left"
                            sx={{
                                marginTop: '16px',
                                padding: '4px 16px 12px',
                                background: '#F2F2F2',
                                boxShadow: '0px 8px 8px 0px #0000001A',
                                borderRadius: '12px',
                                maxWidth: '100%',
                            }}
                        >
                            <Stack spacing={0} direction="row" alignItems={'center'}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '20px',
                                        textAlign: 'left',
                                        paddingTop: '8px',
                                        marginInlineEnd: '8px',
                                    }}
                                >
                                    Keyword:
                                </Typography>

                                <Grid container direction="row">
                                    {
                                        filterBy.map((data, i) => {
                                            return (
                                                <div key={i}>
                                                    <Chip
                                                        label={data}
                                                        color="primary"
                                                        sx={{
                                                            marginTop: '8px',
                                                            marginInlineStart: '8px',
                                                            background: '#B3E3FF',
                                                            border: '2px solid #0087C2',
                                                            color: '#0087C2',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontWeight: '600',
                                                            '&:hover': {
                                                                background: '#EAEAEA',
                                                                color: '#FF3030',
                                                                border: '2px solid #FF3030',
                                                            }
                                                        }}
                                                        deleteIcon={
                                                            <IconButton
                                                                sx={{
                                                                    width: '16px',
                                                                    height: '16px',
                                                                    background: '#EAEAEA',
                                                                    '&:hover': {
                                                                        background: '#FF3030',
                                                                    }
                                                                }}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        sx: {
                                                                            padding: '4px',
                                                                            width: '20px',
                                                                            height: '20px',
                                                                            color: '#0087C2',
                                                                            '&:hover': {
                                                                                color: '#EAEAEA'
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        }
                                                        onDelete={
                                                            () => handleDeleteChip(
                                                                data, setFilterBy, filterBy,
                                                                setFilter, setNumPage, setRowsCount,
                                                                getData, stateX, setStateX, setGoTo, columnData,
                                                                rowsCount, sortArr
                                                            )
                                                        }
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </Grid>

                                <div style={{ paddingTop: '8px' }}>
                                    <Button
                                        size='sm'
                                        variant='outlined'
                                        onClick={() => handleReset()}
                                        sx={{
                                            padding: '6px 8px',
                                            borderRadius: '8px',
                                            color: '#EAEAEA',
                                            gap: '4px',
                                            marginInlineStart: '16px',
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }}
                                    >
                                        <Icon
                                            svgIconProps={{
                                                sx: {
                                                    height: '16px',
                                                    width: '16px'
                                                }
                                            }}
                                            iconName={'refresh'}
                                        />
                                        <Typography
                                            sx={{
                                                fontFamily: 'Nunito',
                                                marginTop: '2px !important',
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                lineHeight: '20px',
                                                letterSpacing: '0em',
                                                textAlign: 'center',
                                                color: '#EAEAEA',
                                            }}
                                        >
                                            Reset
                                        </Typography>
                                    </Button>
                                </div>
                            </Stack>
                        </Box>
                        :
                        <></>
                }
            </>
        )
    }

    AddButton(Add, name, isDisabled, isHidden, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    display: isHidden ? 'none !important' : undefined,
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Add()}
                disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'add'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Add {name}
                </Typography>
            </Button>
        )
    }

    EditButton(ShowEdit, name, isDisabled, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => ShowEdit()}
                disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'edit'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Edit {name}
                </Typography>
            </Button>
        )
    }

    DeleteButton(Delete, name, isDisabled, isHidden = false, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #FF3030)' : '',
                    display: isHidden ? 'none !important' : undefined,
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #FF3030) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Delete()}
                disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#EAEAEA' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'delete'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#EAEAEA',
                    }}
                >
                    Delete {name}
                </Typography>
            </Button>
        )
    }

    UnDeleteButton(UnDelete, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => UnDelete()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'delete'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Un Delete {name}
                </Typography>
            </Button>
        )
    }

    ReloadButton(ReloadData, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => ReloadData()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'convert'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Reload Data
                </Typography>
            </Button>
        )
    }

    FindButton(FindData, name = 'Find', isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => FindData()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'search'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    SaveButton(Save, isDisabled, isHidden, isForm = false, name = '') {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    display: isHidden ? 'none !important' : undefined,
                    background: isForm ? 'var(--Blue-Button-Primary-1, #0087C2)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #0087C2) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Save()} disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#E4E8EC' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'save'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#E4E8EC',
                    }}
                >
                    Save {name}
                </Typography>
            </Button>
        )
    }

    CloseButton(Cancel) {
        return (
            <Button
                className='d-flex align-items-center'
                sx={{
                    padding: '10px 24px !important',
                    gap: '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '32px !important',
                    borderRadius: '8px',
                    border: '1px solid var(--Red-Alert, #D20C0C)',
                    background: 'rgba(210, 12, 12, 0.25)',
                    '&:hover': {
                        border: '1px solid var(--Red-Alert, #D20C0C)',
                        background: 'rgba(210, 12, 12, 0.25)',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Cancel()}
            >
                <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
                    <Icon
                        svgIconProps={{
                            htmlColor: '#D20C0C',
                            sx: {
                                height: '18px',
                                width: '18px'
                            }
                        }}
                        iconName={'close'}
                    />
                    <Typography
                        sx={{
                            fontFamily: 'Nunito',
                            marginTop: '2px !important',
                            fontSize: '16px',
                            fontWeight: '700',
                            lineHeight: '20px',
                            textAlign: 'center',
                            color: '#D20C0C',
                        }}
                    >
                        Cancel
                    </Typography>
                </Stack>
            </Button>
        )
    }

    BackButton(Back, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Back()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'return'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Back
                </Typography>
            </Button>
        )
    }

    PrintButton(Print, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Print()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'printer'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Print {name}
                </Typography>
            </Button>
        )
    }

    ApproveButton(Approve, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Approve()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'approve-doc'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    UnApproveButton(UnApprove, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => UnApprove()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'unapprove-doc'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    CloseOpenJobButton(CloseJob, title, isDisabled, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => CloseJob()} disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'briefcase'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {title} Job
                </Typography>
            </Button>
        )
    }

    SelectButton(Select, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #0087C2)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #0087C2) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Select()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#E4E8EC' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'select'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#E4E8EC',
                    }}
                >
                    Select
                </Typography>
            </Button>
        )
    }

    CancelButton(Cancel, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Cancel()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'cancel'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Cancel
                </Typography>
            </Button>
        )
    }

    SubmitButton(Submit, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Submit()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'submit-doc'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Submit
                </Typography>
            </Button>
        )
    }

    UnApprovalButton(UnApprove, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => UnApprove()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'reject'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name === '' ? 'Un-Approval' : name}
                </Typography>
            </Button>
        )
    }

    UnVerifyButton(UnVerify, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => UnVerify()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'reject'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    ReprintButton(RePrint, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => RePrint()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'print-doc'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Reprint {name}
                </Typography>
            </Button>
        )
    }

    CopyButton(CopyData, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => CopyData()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'copy'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Copy {name}
                </Typography>
            </Button>
        )
    }

    PPH23Button(PPh23, isDisabled, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => PPh23()} disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'tax'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    PPh23
                </Typography>
            </Button>
        )
    }

    ResetButton(Reset, isDisabled, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Reset()} disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'refresh'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Reset
                </Typography>
            </Button>
        )
    }

    DeliveryButton(Delivery, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Delivery()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                            transform: 'rotateY(180deg)'
                        }
                    }}
                    iconName={'delivery'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    JournalButton(Journal, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Journal()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'journal'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Journal
                </Typography>
            </Button>
        )
    }

    ChangeButton(Change, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Change()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'change'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Change {name}
                </Typography>
            </Button>
        )
    }

    AddTimeButton(AddTime, name, isHidden, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    display: isHidden ? 'none !important' : undefined,
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => AddTime()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'add-time'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    IgnoreButton(Ignore, isForm = false) {
        return (
            <Button
                size='sm'
                variant="outlined"
                className='me-2 d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Ignore()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'ignore-doc'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Ignore
                </Typography>
            </Button>
        )
    }

    DemurrageButton(Demurrage, isHidden, isDisabled, isForm = false) {
        return (
            <Button
                size='sm'
                variant="outlined"
                className='me-2 d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    display: isHidden ? 'none !important' : undefined,
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Demurrage()} disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'dollar-circle'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Demurrage
                </Typography>
            </Button>
        )
    }

    OpenButton(Open, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Open()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'folder-open'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    ExcelButton(PrintExcel, name, isDisabled, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => PrintExcel()} disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'excel'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    DetailButton(Detail, name, isDisabled, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Detail()} disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'detail'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    MemorialButton(Send, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Send()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'add'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    AdjustButton(Adjust, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Adjust()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'adjust'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    AssessmentButton(Assessment, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Assessment()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'add'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    FilterButton(Filter, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Filter()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'filter'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    UpdateSubOrderButton(Update, name, isHidden, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    display: isHidden ? 'none !important' : undefined,
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Update()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'add'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    FinishButton(Finish, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Finish()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'finish'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Finish
                </Typography>
            </Button>
        )
    }

    ConfigButton(Config, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Config()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'config'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Config {name}
                </Typography>
            </Button>
        )
    }

    ArchiveButton(Archive, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Archive()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'archive'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    RemoveButton(Remove, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Remove()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'remove'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Remove
                </Typography>
            </Button>
        )
    }

    InsertButton(Insert, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Insert()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'insert'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    SettingsButton(Settings, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Settings()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'config'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Settings
                </Typography>
            </Button>
        )
    }

    RestoreButton(Restore, name, isHidden, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    display: isHidden ? 'none !important' : undefined,
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Restore()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'restore'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    UploadButton(Upload, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Upload()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'upload'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Upload
                </Typography>
            </Button>
        )
    }

    ConvertButton(Convert, isDisabled = false, isHidden = false, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    display: isHidden ? 'none !important' : undefined,
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    },
                }}
                onClick={() => Convert()}
                disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'convert'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Convert
                </Typography>
            </Button>
        )
    }

    ProcessButton(Process, isDisabled, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Process()} disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'convert'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Process
                </Typography>
            </Button>
        )
    }

    PutAwayButton(PutAway, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => PutAway()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'add'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Put Away
                </Typography>
            </Button>
        )
    }

    PickingButton(Picking, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Picking()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'hand'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    Picking
                </Typography>
            </Button>
        )
    }

    ViewButton(View, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => View()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'search-doc'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    DeclineButton(Decline, name, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => Decline()}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={'decline-doc'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    Custom(action, icon, name, disabled, isForm = false) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: !isForm ? '3px 6px' : '4px 12px !important',
                    gap: !isForm ? '4px' : '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: !isForm ? '18px !important' : '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF)' : '',
                    '&:hover': {
                        backgroundColor: isForm ? 'var(--Blue-Button-Primary-1, #B3E3FF) !important' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => action()} disabled={disabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: isForm ? '#0087C2' : '',
                        sx: {
                            height: !isForm ? '12px' : '16px',
                            width: !isForm ? '12px' : '16px',
                        }
                    }}
                    iconName={icon}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '2px !important',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: !isForm ? 'inherit' : '#0087C2',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }

    OpenCollapseButton(isOpen, setIsOpen, name, isDisabled) {
        return (
            <Button
                size='small'
                variant="outlined"
                className='d-flex align-items-center'
                sx={{
                    padding: '3px 6px',
                    gap: '4px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '18px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    '&:hover': {
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                }}
                onClick={() => setIsOpen(!isOpen)}
                disabled={isDisabled}
            >
                <Icon
                    svgIconProps={{
                        htmlColor: '',
                        sx: {
                            height: '12px',
                            width: '12px',
                            marginTop: !isOpen ? '1px !important' : '0px !important',
                        }
                    }}
                    iconName={isOpen ? 'arrow-up' : 'arrow-down'}
                />
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: 'inherit',
                    }}
                >
                    {name}
                </Typography>
            </Button>
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ButtonComponent()