import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Grid, Box } from '@mui/material';

import ApiService from '../../../services/ApiService';
import TableX from '../../../components/Tables/TableX';
import ListEplButton from './ComponentEPL/ListEplButton';
import ModalDelete from '../../../components/Modals/ModalDelete';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';

import freezeContainer from '../../../Styles/freezeContainer';
import ModalUsageData from '../../../components/Modals/ModalUsageData';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';
import { newWindowConfig } from '../../../utils/masks.jsx';

const ListEpl = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false);
    const history = useNavigate()
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const param = 'estimateProfitLoss/estimateProfitLoss'
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);

    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const [DropDownTitle, setDropDownTitle] = useState('All');

    const [dataJobType, setDataJobType] = useState([]);
    const [jobTypeId, setJobTypeId] = useState('' + JSON.parse(localStorage.getItem("jobTypeId")) === '0' ? '1' : '' + JSON.parse(localStorage.getItem("jobTypeId")));
    const paramRegJobType = 'regJobType/regJobType';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const [isModalUsage, setIsModalUsage] = useState(false);
    const [titleModalUsage, setTitleModalUsage] = useState('');
    const [usageDataList, setUsageDataList] = useState([]);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Estimate Profit & Loss', link: '/Majura/EstimateProfitLoss', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Estimate Profit and Loss - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy, data) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let filter;
        let params = [{
            "attr": "jobTypeId",
            "value": '' + jobTypeId
        }]

        if (data) {
            if (data === 'All') {
                filter = [...filters]
            }
            else if (data === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
            }
            else if (data === 'Active') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
            }
        }
        else {
            if (DropDownTitle === 'All') {
                filter = [...filters]
            }
            else if (DropDownTitle === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
            }
            else if (DropDownTitle === 'Active') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
            }
        }

        ApiService.ListOperationDynamicPostByPage(param, 'PAGE', pageNumber, pageSize, params, filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.estimateProfitLoss
                    setSelectedData({})

                    let temp = data
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getData1 = (pageNumber, pageSize, filters, jobId) => {
        setIsLoading(true);
        let filter;
        let params = [{
            "attr": "jobTypeId",
            "value": '' + jobId
        }]

        if (DropDownTitle === 'All') {
            filter = [...filters]
        }
        else if (DropDownTitle === 'Deleted') {
            filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
        }
        else if (DropDownTitle === 'Active') {
            filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
        }

        ApiService.ListOperationDynamicPostByPage(param, 'PAGE',
            pageNumber, pageSize, params, filter)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.estimateProfitLoss
                    setSelectedData({})

                    let temp = data
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    setIsLoading(false);
                }

                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OEPLO')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        if (hasAccess) {
                            GetJobType(1, 50);
                        }
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClick = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const handleClickUsage = () => {
        if (isModalUsage === false) {
            setIsModalUsage(true);
        }
        else {
            setIsModalUsage(false);
        }
    }

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setGoTo(1);
        setFilterBy([]);
        setStateX({})
        setSortArr([])
    };

    const Add = () => {
        history('/Majura/EstimateProfitLoss/Create/' + jobTypeId);
    }

    const Edit = () => {
        let check = 0;
        DataRegUserAccess.forEach(element => {
            if (element.accessCode === 'REI') {
                check = 1;
            }
        });

        if (check !== 0) {
            if (SelectedData.id === undefined) {
                showToast({ type: 'error', message: 'Please Select Data!' });
            } else {
                window.open('/Majura/EstimateProfitLoss/' + jobTypeId + '/' + SelectedData.id, newWindowConfig);
            }
        }
        else {
            showToast({ type: 'error', message: 'You Don\'t Have Access!' });
        }
    }

    const Reload = () => {
        showToast({ type: 'success', message: 'Reload Data Success!' });

        setFilter([])
        emptyState();
        getData(1, 50, [])
    }

    const Delete = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setIsLoadingBackDrop(true);
            if (SelectedData.rowStatus === 'ACT') {
                let params = [
                    {
                        "attr": "isCheckINVPR",
                        "value": "true"
                    },
                    {
                        "attr": "eplId",
                        "value": '' + SelectedData.id
                    },
                    {
                        "attr": "refId",
                        "value": "0",
                        "isDistinct": true
                    }
                ];

                ApiService.OperationPostByDetail(param, 'SEARCH', 1, 20, params, [])
                    .then((response) => {
                        if (response.data.code === 200) {
                            if (response.data.data.estimateProfitLossDetail.length > 0) {
                                getDataRelated(response.data.data.estimateProfitLossDetail);
                            }
                            else {
                                if (SelectedData.approved) {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: 'Can not delete data, already approved!' });
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    setTitleModalDelete('Estimate Profit Loss');
                                    handleClick();
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        console.error('error saat fetch', error)
                        history('/Majura');
                    })
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const DeleteEPL = () => {
        ApiService.OperationDelete(param, SelectedData.id).then((res) => {
            if (res.data.code === 200) {
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: 'Delete Data Success!' });
                setIsModalDelete(false);
            }
            else {
                showToast({ type: 'error', message: 'Delete Data Failed!' });
                setIsModalDelete(false);
            }
        });
    }

    const getDataRelated = (array) => {
        let sequence = 1;
        let tempArray = [];
        let source = '';

        array.forEach(element => {
            if (element.isIncome === false) {
                source = 'Payment Request';
            }
            else if (element.isIncome === true) {
                source = 'Invoice';
            }

            tempArray = [...tempArray,
            {
                'sequence': sequence,
                'usedOn': source,
                'transactionNumber': element.refReference
            }
            ];

            sequence = sequence + 1;
        });

        setIsLoadingBackDrop(false);
        setUsageDataList(tempArray);
        setTitleModalUsage('Estimate Profit Loss');
        handleClickUsage();
    }

    const UnDelete = () => {

    }

    const Print = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                setIsLoadingBackDrop(true);
                let variable = 'PrintEPL?Id=' + SelectedData.id;
                ApiService.ReportPrint('Transactions/Operation/EstimateProfitLossReport/', variable)
                    .then(response => {
                        if (response.status === 200) {
                            const file = new Blob(
                                [response.data],
                                { type: 'application/pdf' }
                            );

                            const fileURL = URL.createObjectURL(file);

                            setIsLoadingBackDrop(false);
                            showToast({ type: 'success', message: 'Print EPL Success!' });
                            window.open(fileURL);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: 'Can\'t Print EPL!' });
                        }
                    })
                    .catch(error => {
                        setIsLoadingBackDrop(false);
                        console.log(error);
                        showToast({ type: 'error', message: error.toString() });
                    });
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const handleReset = () => {
        setGoTo(1);
        setFilter([])
        setFilterBy([])
        emptyState();
        getData(1, 50, [])
        setStateX({})
    }

    function CellDoubleClick(row) {
        Edit()
    }

    const GetJobType = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramRegJobType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataJobType(response.data.data.jobType);

                    getData(1, 50, []);
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' })
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
                console.error('error', error)
                showToast({ type: 'error', message: error.toString() })
            })
    }

    function handleSelect(e, c) {
        localStorage.setItem("jobTypeId", JSON.stringify(c.props.id));
        setJobTypeId(c.props.id);
        getData1(1, 50, filter, c.props.id)
    }

    function HandleStatus(data) {
        setDropDownTitle(data);
        getData(1, 50, filter, sortArr, data);
    }

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const ButtonComponents = () => {
        return (
            <ListEplButton
                Add={Add}
                Edit={Edit}
                Reload={Reload}
                Delete={Delete}
                UnDelete={UnDelete}
                Print={Print}
                jobTypeId={jobTypeId}
                handleSelect={handleSelect}
                dataJobType={dataJobType}
                DataRegUserAccess={DataRegUserAccess}
                DropDownTitle={DropDownTitle}
                HandleStatus={HandleStatus}
            />
        )
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Estimate Profit & Loss'}
                    isJobType={true}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                    jobTypeId={jobTypeId}
                    handleSelect={handleSelect}
                    dataJobType={dataJobType}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            'borderRadius': '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <TableX
                                        uniqueKey={'id'}
                                        goTo={goTo}
                                        rowsCount={rowsCount}
                                        setNumPage={setNumPage}
                                        setRowsCount={setRowsCount}
                                        getData={getData}
                                        columnData={columnData}
                                        dataMap={dataMap}
                                        SelectedData={SelectedData}
                                        setSelectedData={setSelectedData}
                                        setFilter={setFilter}
                                        setFilterBy={setFilterBy}
                                        CellDoubleClick={CellDoubleClick}
                                        stateX={stateX}
                                        setStateX={setStateX}
                                        sortArr={sortArr}
                                        setSortArr={setSortArr}
                                        setGoTo={setGoTo}
                                    />
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClick}
                        DeleteData={DeleteEPL}
                    />
                )
            }

            {
                isModalUsage && (
                    <ModalUsageData
                        isModal={isModalUsage}
                        titleModal={titleModalUsage}
                        handleClick={handleClickUsage}
                        usageDataList={usageDataList}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default ListEpl