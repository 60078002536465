import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableCheck from '../../../../components/Tables/TableCheck';
import { Grid, Box, Stack, MenuItem } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ButtonAdjustmentAPList from './Buttons/ButtonAdjustmentAPList';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import freezeContainer from '../../../../Styles/freezeContainer';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Input, Paginate, Selection, showToast } from '../../../../components/ui';
import { setDateFormat, updateDateFormat } from '../../../../utils/date';

const AdjustmentAPList = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramAdjustmentAP = 'memorial/AdjustmentAP';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);
    const [dataCheck, setDataCheck] = useState([]);

    const [asPerDate, setAsPerDate] = useState();

    const [dataJobType, setDataJobType] = useState([]);
    const [jobTypeId, setJobTypeId] = useState('1');
    const paramRegJobType = 'regJobType/regJobType';

    const [dataContactType, setDataContactType] = useState([]);
    const [contactTypeId, setContactTypeId] = useState('4');
    const paramContactType = 'contactType/contactType';

    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isDueDate, setIsDueDate] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [inputLabel, setInputLabel] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Adjustment AP', link: '/Majura/AdjustmentAP', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Adjustment AP - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        localStorage.setItem('jobTypeId', '1');
        localStorage.setItem('contactTypeId', '4');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters = [], orderBy) => {
        let dateInput = '';
        let params = [];
        let contactTypeId = localStorage.getItem('contactTypeId');
        let jobTypeId = localStorage.getItem('jobTypeId');
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        if (asPerDate !== '' && asPerDate !== undefined) {
            dateInput = asPerDate;
        }
        else {
            dateInput = updateDateFormat(new Date());
        }

        params = [
            {
                'attr': 'jobTypeId',
                'value': '' + jobTypeId
            },
            {
                'attr': 'contactTypeId',
                'value': '' + contactTypeId
            },
            {
                'attr': 'date',
                'value': dateInput
            }
        ]

        ApiService.ListFinAccPostByPage(paramAdjustmentAP, 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.adjustmentAP
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false)
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FGIN')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        GetJobType(1, 50);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false)
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetJobType = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramRegJobType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataJobType(response.data.data.jobType);

                    getContactTypeData(1, 50);
                } else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }
            })
            .catch(function (error) {
                console.error('error', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getContactTypeData = (pageNumber, pageSize) => {
        let tempArray = [];
        const includesData = ['SSLINE', 'IATA', 'DISCOUNT SHIPPER', 'EMKL', 'DEPO', 'DISCOUNT CONSIGNEE'];
        ApiService.PostByPage(paramContactType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    let array = response.data.data.contactType;
                    tempArray = array.filter((el) => includesData.includes(el?.name?.toUpperCase()));
                    setDataContactType(tempArray);

                    getDateNow();
                    getData(1, 50, []);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const handleReset = () => {
        emptyState();
        getData(1, 50, []);
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
            setInputLabel('');
            setIsDueDate(false);
        }
    }

    function ConfirmationAP() {
        if (titleConfirmation === 'Print') {
            PrintPDF();
        }
        else if (titleConfirmation === 'Memorial') {
            UpdateMemo();
        }
        setIsModalConfirmation(false);
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        emptyState();
        getData(1, 50, []);
    }

    const PrintData = () => {
        if (dataCheck.length >= 1) {
            setIsDueDate(false);
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Adjustment AP');
            handleClickConfirmation();
        }
        else {
            showToast({ type: 'error', message: 'Please Select At Least 1 Data' });
        }
    }

    const PrintPDF = () => {
        setIsLoadingBackDrop(true);
        let id = '';

        dataCheck.forEach(element => {
            if (id !== '') {
                id = id + ',' + element.eplDetailId;
            }
            else {
                id = element.eplDetailId;
            }
        });

        let variable = 'AdjustmentAP?eplDetailIds=' + id + '&date=' + dueDate;
        ApiService.ReportPrint('Transactions/Finance/memorialReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Adjustment AP Success' });
                    window.open(fileURL);
                    ReloadData();
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Adjustment AP, ' + response.data.message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Adjustment AP, ' + error.toString() });
            });
    }

    const AddMemo = () => {
        if (dataCheck.length >= 1) {
            getDateNow();
            setIsDueDate(true);
            setInputLabel('Adjustment AP Date');
            setTitleConfirmation('Memorial')
            setTitleModalConfirmation('Adjustment AP');
            handleClickConfirmation();
        }
        else {
            showToast({ type: 'error', message: 'Please Select At Least 1 Data' });
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
        setDateFormat(new Date(), setAsPerDate);
    };

    const UpdateMemo = () => {
        setIsLoadingBackDrop(true);
        let adjustmentAP = [];

        dataCheck.forEach(element => {
            let object = {
                "amountUsd": element.amountUsd,
                "amountIdr": element.amountIdr,
                "eplDetailId": element.eplDetailId,
                "customerId": element.customerId,
                "shipmentOrderId": element.shipmentOrderId
            }

            adjustmentAP = [...adjustmentAP, object];
        });

        var data = {
            "date": asPerDate,
            "adjustDate": dueDate,
            "adjustmentAP": adjustmentAP
        }

        ApiService.HelpCreateData(data, paramAdjustmentAP, 0)
            .then((res) => {
                if (res.data.code === 200) {
                    let message = '';
                    ApiService.CustomFinanceCreate(paramAdjustmentAP, data).then((res) => {
                        if (res.data.code === 200) {
                            setIsLoadingBackDrop(false);
                            message = 'Create Data Memorial Success';
                            showToast({ type: 'success', message: message });
                            setTimeout(
                                function () {
                                    ReloadData();
                                },
                                1250);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: res.data.message });
                        }
                    });
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: res.data.message });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error.toString() });
            })
    }

    const emptyState = () => {
        setDataCheck([]);
        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    function handleSelect(e, c, type) {
        if (type === 'job') {
            setJobTypeId(c.props.id);
            localStorage.setItem('jobTypeId', c.props.id);
        }
        else if (type === 'contact') {
            setContactTypeId(c.props.id);
            localStorage.setItem('contactTypeId', c.props.id);
        }

        emptyState();
        getData(1, 50, []);
    }

    const ButtonComponents = () => {
        return (
            <>
                <ButtonAdjustmentAPList
                    ReloadData={ReloadData}
                    Print={PrintData}
                    AddMemo={AddMemo}
                    DataRegUserAccess={DataRegUserAccess}
                />

                <Stack spacing={'16px'} direction="row" alignItems={'center'} sx={{ width: '100%', marginTop: '16px' }}>
                    <Input
                        type={'date'}
                        label='As Per Date'
                        value={asPerDate}
                        onChange={(e) => setAsPerDate(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '263px'
                            }
                        }}
                        formLabelProps={{
                            sx: {
                                fontSize: '12px',
                            }
                        }}
                        inputBaseProps={{
                            sx: {
                                fontSize: '12px !important',
                                minHeight: '28px !important',
                                maxHeight: '28px !important',
                                padding: '3px 6px',
                            }
                        }}
                    />

                    <Selection
                        id='0'
                        label={'Job Type'}
                        value={jobTypeId}
                        minWidth={263}
                        placeholder={'Select Job Type'}
                        onChange={(e, c) => handleSelect(e, c, 'job')}
                        formLabelProps={{
                            sx: {
                                fontSize: '12px',
                            }
                        }}
                        selectBaseProps={{
                            sx: {
                                fontSize: '12px !important',
                                minHeight: '28px !important',
                                maxHeight: '28px !important',
                                padding: '3px 6px',
                            }
                        }}
                    >
                        {
                            dataJobType.map((data) => (
                                <MenuItem sx={{ fontSize: '12px', 'textAlign': 'center' }} id={data.id} key={data.code} value={data.id}>
                                    {data.name}
                                </MenuItem>
                            ))
                        }
                    </Selection>

                    <Selection
                        id='0'
                        label={'Contact Type'}
                        value={contactTypeId}
                        minWidth={263}
                        placeholder={'Select Contact Type'}
                        onChange={(e, c) => handleSelect(e, c, 'contact')}
                        formLabelProps={{
                            sx: {
                                fontSize: '12px',
                            }
                        }}
                        selectBaseProps={{
                            sx: {
                                fontSize: '12px !important',
                                minHeight: '28px !important',
                                maxHeight: '28px !important',
                                padding: '3px 6px',
                            }
                        }}
                    >
                        {
                            dataContactType.map((data) => (
                                <MenuItem sx={{ fontSize: '12px', 'textAlign': 'center' }} id={data.id} key={data.code} value={data.id}>
                                    {data.name}
                                </MenuItem>
                            ))
                        }
                    </Selection>
                </Stack>
            </>
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo btnPageRef={btnPageRef}
                    title={'Adjustment AP'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        <TableCheck
                                            uniqueKey={'eplDetailId'}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            dataCheck={dataCheck}
                                            setDataCheck={setDataCheck}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                            goTo={goTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>
            
            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationAP}

                        isDueDate={isDueDate}
                        setIsDueDate={setIsDueDate}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        inputLabel={inputLabel}
                    />
                )
            }
        </Box>
    )
})

export default AdjustmentAPList